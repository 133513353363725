import _ from 'lodash';

export const MAX_ITEMS = 17

export const FLT_IND_BYTE_TKT = [
  {value: '0', label: '日本国内線区間', },
  {value: '1', label: '日本着国際線区間', },
  {value: '2', label: '日本発国際線区間', },
  {value: '3', label: '日本発着以外の区間', },
]
export const LIST_ITEMS = {
  ticket: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
    },
    {
      id: 'tktParentTktno',
      header: '親TKT NO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPaxType',
      header: 'PAX TYPE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPtc',
      header: 'PTC（旅客タイプコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTktType',
      header: '券種',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareType',
      header: '運賃種別',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIata',
      header: 'IATAコード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCrs',
      header: 'GDS区分',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPnr',
      header: 'PNR リファレンス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOrderId',
      header: 'ORDER ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktNdcFlg',
      header: 'NDCフラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktBookingPcc',
      header: '予約OFFICE ID/PCC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTktPcc',
      header: '発券OFFICE ID/PCC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRcvDate',
      header: '受信日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRcvTime',
      header: '受信時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRstDate',
      header: '登録日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRstTime',
      header: '登録時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIntDepDate',
      header: '国際線出発日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktMemo',
      header: 'メモ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee',
      header: 'AIRLINE名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej',
      header: 'AIRLINE名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lOdrCd',
      header: '受注コード（R）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lSecCd',
      header: 'セクションコード（S）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lMbrCd',
      header: '担当者コード（A）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lComCd',
      header: '法人コード（C）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lAreaCd',
      header: '方面コード（D）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lFreeCd',
      header: 'FREEコード（F）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lPayCd',
      header: '入金コード（M）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lTourCd',
      header: 'ツアーコード（T）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lKb1Cd',
      header: 'KBコード1（K）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lKb2Cd',
      header: 'KBコード2（B）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lEoCd',
      header: 'E/Oコード（E）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7OdrNamej',
      header: '受注コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7SecNamej',
      header: 'セクションコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'mbrNamee',
      header: '担当者名（英字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'mbrNamej',
      header: '担当者名（漢字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comNamee',
      header: '法人名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comNamej',
      header: '法人名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comSecNamee',
      header: '部署名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comSecNamej',
      header: '部署名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7AreaNamej',
      header: '方面コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7PayNamej',
      header: '入金コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7FreeNamej',
      header: 'FREEコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7Kb1Namej',
      header: 'KBコード1名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7Kb2Namej',
      header: 'KBコード2名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7EoNamej',
      header: 'E/Oコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGsCash',
      header: 'GROSS/CASH',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktGsCredit',
      header: 'GROSS/CREDIT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxCash',
      header: 'TAX/CASH',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxCredit',
      header: 'TAX/CREDIT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktCommRate',
      header: 'COMMISSION RATE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'commSum0',
      header: 'COMMISSION額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'commTax',
      header: 'COMMISSION額消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFareCalMode',
      header: 'FCMI',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareCalPricingInd',
      header: 'FCPI',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareCalculation',
      header: 'FARE CALCULATION',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFare',
      header: 'FARE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTotal',
      header: 'TOTAL',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEqFapd',
      header: 'EQUIVALENT FARE PAID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEndRest',
      header: 'ENDORSEMENTS/RESTRICTIONS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFormPay',
      header: 'FORM OF PAYMENT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'salesAmount',
      header: 'SALES AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktPrice',
      header: 'TKT原価',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'nsbNumber',
      header: 'NSB額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tkt2aNet',
      header: '2A NET額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'bspReqSum',
      header: 'BSP請求額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTokuiClm',
      header: '得意先請求金額（入力）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customSeikyuSum',
      header: '得意先請求金額（計算値）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customSum',
      header: '得意先金額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customTax',
      header: '得意先消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'itsCompanyCom',
      header: '自社手数料',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNrid',
      header: 'NR-ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktItno',
      header: 'IT-NO',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVc',
      header: 'VC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCar',
      header: 'CAR',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTourCd',
      header: 'TOUR CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGnr5Flg',
      header: 'GNR5対象フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktMonthStepoverFlg',
      header: '月跨ぎフラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCustomerOutputFlg',
      header: 'お客様用発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktAgentCouponOutputFlg',
      header: 'AGENT COUPON発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCccfOutputFlg',
      header: 'CCCF発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefundNoticeOutputFlg',
      header: 'REFUND NOTICE発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVoidId',
      header: 'VOID ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOvoId',
      header: 'オンラインVOID ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVoidDate',
      header: 'VOID処理日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefId',
      header: 'REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktAtRefid',
      header: 'AUTO REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefType',
      header: 'REFUND区分（F/A/L/M）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefDate',
      header: 'REFUND処理日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktLesCntPen',
      header: 'CANCEL PENALTY',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktWaiverCode',
      header: 'WAIVER CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo1',
      header: 'COUPON1 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo2',
      header: 'COUPON2 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo3',
      header: 'COUPON3 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo4',
      header: 'COUPON4 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGrsFarePd',
      header: 'GROSS FARE PAID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFrUsed',
      header: 'GROSS FARE USED',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktGrsFareRef',
      header: 'GROSS FARE REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxRef',
      header: 'TAX REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTotalAmt',
      header: 'TOTAL AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNetFareRef',
      header: 'NET FARE REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNetFare',
      header: 'NET FARE ADJUSTMENT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNfa',
      header: 'TOTAL AMOUNT（NFA）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktRefComm',
      header: 'REFUND COMMISSION額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktRefCommTax',
      header: 'REFUND COMMISSION額消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktBalancePayable',
      header: 'BALANCE PAYABLE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktSac',
      header: 'SAC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefMemo',
      header: 'REFUND MEMO',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefPfc',
      header: 'REFUND PFC(XF) DATA',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReissueId',
      header: 'REISSUE ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOrgIsu',
      header: 'ORIGINAL ISSUE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIsuEx',
      header: 'ISSUED IN EXCHANGE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCreditCom',
      header: 'クレジットカード会社コード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCardNum',
      header: 'クレジットカードNO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaExdate',
      header: 'CARD有効期限',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaMem',
      header: 'CARD会員名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaAprid',
      header: 'CARD承認カードID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaAprno',
      header: 'CARD承認番号',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaApramt',
      header: 'CARD承認金額',
      format: null,
      dataType: 'Number',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFuncCd',
      header: '機能分類コード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks1',
      header: 'CARD RMKS1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks2',
      header: 'CARD RMKS2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks3',
      header: 'CARD RMKS3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktConjData',
      header: 'CONJUNCTION TICKET DATA',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReasonIssuanceCode',
      header: 'REASON FOR ISSUANCE CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReasonIssuanceDesc',
      header: 'REASON FOR ISSUANCE DESCRIPTION',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEmdRmks',
      header: 'EMD TKT REMARKS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    // {
    //   id: 'tktEmdFlag',
    //   header: 'EMDフラグ',
    //   format: null,
    //   isReadOnly: true,
    //   width: 200,
    //   minWidth: 50,
    //   allowSorting: true,
    //   bulkEdit: false,
    //   disabled: false,
    //   align: 'left',
    // },
    {
      id: 'fltCpnNo1',
      header: 'フライトクーポン番号1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltSeg1',
      header: 'FLIGHT SEGMENT NO.1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltStopoverCode1',
      header: 'STOP OVER CODE1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltAirlineCd1',
      header: 'FLIGHT AIRLINEコード1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltNum1',
      header: 'FLT NO.1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltClass1',
      header: '搭乗クラス1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFrom1',
      header: '出発地1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltTo1',
      header: '到着地1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFareBasis1',
      header: 'FARE BASIS1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardDate1',
      header: '搭乗日1',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardTime1',
      header: '搭乗時間1',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrDate1',
      header: '到着日1',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrTime1',
      header: '到着時間1',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNva1',
      header: 'NVA1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNvb1',
      header: 'NVB1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBagAlw1',
      header: 'BAGGAGE ALLOWANCE1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'supportSum1',
      header: 'サポート額1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'fltReservationStatus1',
      header: '予約ステータス1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltIndByteTkt1',
      header: 'フライト区間種別1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee1',
      header: 'FLIGHT AIRLINE名(英字)1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej1',
      header: 'FLIGHT AIRLINE名(漢字)1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltCpnNo2',
      header: 'フライトクーポン番号2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltSeg2',
      header: 'FLIGHT SEGMENT NO.2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltStopoverCode2',
      header: 'STOP OVER CODE2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltAirlineCd2',
      header: 'FLIGHT AIRLINEコード2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltNum2',
      header: 'FLT NO.2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltClass2',
      header: '搭乗クラス2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFrom2',
      header: '出発地2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltTo2',
      header: '到着地2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFareBasis2',
      header: 'FARE BASIS2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardDate2',
      header: '搭乗日2',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardTime2',
      header: '搭乗時間2',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrDate2',
      header: '到着日2',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrTime2',
      header: '到着時間2',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNva2',
      header: 'NVA2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNvb2',
      header: 'NVB2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBagAlw2',
      header: 'BAGGAGE ALLOWANCE2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'supportSum2',
      header: 'サポート額2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'fltReservationStatus2',
      header: '予約ステータス2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltIndByteTkt2',
      header: 'フライト区間種別2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee2',
      header: 'FLIGHT AIRLINE名(英字)2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej2',
      header: 'FLIGHT AIRLINE名(漢字)2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltCpnNo3',
      header: 'フライトクーポン番号3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltSeg3',
      header: 'FLIGHT SEGMENT NO.3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltStopoverCode3',
      header: 'STOP OVER CODE3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltAirlineCd3',
      header: 'FLIGHT AIRLINEコード3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltNum3',
      header: 'FLT NO.3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltClass3',
      header: '搭乗クラス3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFrom3',
      header: '出発地3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltTo3',
      header: '到着地3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFareBasis3',
      header: 'FARE BASIS3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardDate3',
      header: '搭乗日3',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardTime3',
      header: '搭乗時間3',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrDate3',
      header: '到着日3',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrTime3',
      header: '到着時間3',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNva3',
      header: 'NVA3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNvb3',
      header: 'NVB3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBagAlw3',
      header: 'BAGGAGE ALLOWANCE3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'supportSum3',
      header: 'サポート額3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'fltReservationStatus3',
      header: '予約ステータス3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltIndByteTkt3',
      header: 'フライト区間種別3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee3',
      header: 'FLIGHT AIRLINE名(英字)3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej3',
      header: 'FLIGHT AIRLINE名(漢字)3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltCpnNo4',
      header: 'フライトクーポン番号4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltSeg4',
      header: 'FLIGHT SEGMENT NO.4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltStopoverCode4',
      header: 'STOP OVER CODE4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltAirlineCd4',
      header: 'FLIGHT AIRLINEコード4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltNum4',
      header: 'FLT NO.4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltClass4',
      header: '搭乗クラス4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFrom4',
      header: '出発地4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltTo4',
      header: '到着地4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFareBasis4',
      header: 'FARE BASIS4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardDate4',
      header: '搭乗日4',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardTime4',
      header: '搭乗時間4',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrDate4',
      header: '到着日4',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrTime4',
      header: '到着時間4',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNva4',
      header: 'NVA4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNvb4',
      header: 'NVB4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBagAlw4',
      header: 'BAGGAGE ALLOWANCE4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'supportSum4',
      header: 'サポート額4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'fltReservationStatus4',
      header: '予約ステータス4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltIndByteTkt4',
      header: 'フライト区間種別4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee4',
      header: 'FLIGHT AIRLINE名(英字)4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej4',
      header: 'FLIGHT AIRLINE名(漢字)4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxCount',
      header: 'TAX COUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'emdCpnNo1',
      header: 'EMD CPN NO.1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCode1',
      header: 'REASON FOR ISSUANCE SUB CODE1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc1',
      header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnFieldFeeAmt1',
      header: 'COUPON FILED FEE AMOUNT1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnValueAmt1',
      header: 'COUPON VALUE AMOUNT1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdConsumedIssuaranceInd1',
      header: 'CONSUMED AT ISSUANCE INDICATOR1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnRmks1',
      header: 'COUPON REMARKS1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnNo2',
      header: 'EMD CPN NO.2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCode2',
      header: 'REASON FOR ISSUANCE SUB CODE2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc2',
      header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnFieldFeeAmt2',
      header: 'COUPON FILED FEE AMOUNT2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnValueAmt2',
      header: 'COUPON VALUE AMOUNT2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdConsumedIssuaranceInd2',
      header: 'CONSUMED AT ISSUANCE INDICATOR2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnRmks2',
      header: 'COUPON REMARKS2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnNo3',
      header: 'EMD CPN NO.3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCode3',
      header: 'REASON FOR ISSUANCE SUB CODE3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc3',
      header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnFieldFeeAmt3',
      header: 'COUPON FILED FEE AMOUNT3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnValueAmt3',
      header: 'COUPON VALUE AMOUNT3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdConsumedIssuaranceInd3',
      header: 'CONSUMED AT ISSUANCE INDICATOR3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnRmks3',
      header: 'COUPON REMARKS3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnNo4',
      header: 'EMD CPN NO.4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCode4',
      header: 'REASON FOR ISSUANCE SUB CODE4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc4',
      header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnFieldFeeAmt4',
      header: 'COUPON FILED FEE AMOUNT4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnValueAmt4',
      header: 'COUPON VALUE AMOUNT4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdConsumedIssuaranceInd4',
      header: 'CONSUMED AT ISSUANCE INDICATOR4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnRmks4',
      header: 'COUPON REMARKS4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt1',
      header: 'TAX AMOUNT 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd1',
      header: 'TAX INDICATOR 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt2',
      header: 'TAX AMOUNT 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd2',
      header: 'TAX INDICATOR 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt3',
      header: 'TAX AMOUNT 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd3',
      header: 'TAX INDICATOR 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt4',
      header: 'TAX AMOUNT 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd4',
      header: 'TAX INDICATOR 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt5',
      header: 'TAX AMOUNT 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd5',
      header: 'TAX INDICATOR 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt6',
      header: 'TAX AMOUNT 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd6',
      header: 'TAX INDICATOR 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt7',
      header: 'TAX AMOUNT 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd7',
      header: 'TAX INDICATOR 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt8',
      header: 'TAX AMOUNT 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd8',
      header: 'TAX INDICATOR 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt9',
      header: 'TAX AMOUNT 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd9',
      header: 'TAX INDICATOR 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt10',
      header: 'TAX AMOUNT 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd10',
      header: 'TAX INDICATOR 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt11',
      header: 'TAX AMOUNT 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd11',
      header: 'TAX INDICATOR 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt12',
      header: 'TAX AMOUNT 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd12',
      header: 'TAX INDICATOR 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt13',
      header: 'TAX AMOUNT 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd13',
      header: 'TAX INDICATOR 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt14',
      header: 'TAX AMOUNT 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd14',
      header: 'TAX INDICATOR 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt15',
      header: 'TAX AMOUNT 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd15',
      header: 'TAX INDICATOR 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt16',
      header: 'TAX AMOUNT 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd16',
      header: 'TAX INDICATOR 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt17',
      header: 'TAX AMOUNT 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd17',
      header: 'TAX INDICATOR 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt18',
      header: 'TAX AMOUNT 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd18',
      header: 'TAX INDICATOR 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt19',
      header: 'TAX AMOUNT 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd19',
      header: 'TAX INDICATOR 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt20',
      header: 'TAX AMOUNT 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd20',
      header: 'TAX INDICATOR 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt21',
      header: 'TAX AMOUNT 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd21',
      header: 'TAX INDICATOR 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt22',
      header: 'TAX AMOUNT 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd22',
      header: 'TAX INDICATOR 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt23',
      header: 'TAX AMOUNT 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd23',
      header: 'TAX INDICATOR 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt24',
      header: 'TAX AMOUNT 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd24',
      header: 'TAX INDICATOR 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt25',
      header: 'TAX AMOUNT 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd25',
      header: 'TAX INDICATOR 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt26',
      header: 'TAX AMOUNT 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd26',
      header: 'TAX INDICATOR 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt27',
      header: 'TAX AMOUNT 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd27',
      header: 'TAX INDICATOR 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt28',
      header: 'TAX AMOUNT 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd28',
      header: 'TAX INDICATOR 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt29',
      header: 'TAX AMOUNT 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd29',
      header: 'TAX INDICATOR 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt30',
      header: 'TAX AMOUNT 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd30',
      header: 'TAX INDICATOR 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxInd1',
      header: 'REFUND TAX INDICATOR 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt1',
      header: 'REFUND TAX AMOUNT 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd2',
      header: 'REFUND TAX INDICATOR 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt2',
      header: 'REFUND TAX AMOUNT 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd3',
      header: 'REFUND TAX INDICATOR 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt3',
      header: 'REFUND TAX AMOUNT 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd4',
      header: 'REFUND TAX INDICATOR 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt4',
      header: 'REFUND TAX AMOUNT 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd5',
      header: 'REFUND TAX INDICATOR 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt5',
      header: 'REFUND TAX AMOUNT 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd6',
      header: 'REFUND TAX INDICATOR 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt6',
      header: 'REFUND TAX AMOUNT 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd7',
      header: 'REFUND TAX INDICATOR 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt7',
      header: 'REFUND TAX AMOUNT 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd8',
      header: 'REFUND TAX INDICATOR 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt8',
      header: 'REFUND TAX AMOUNT 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd9',
      header: 'REFUND TAX INDICATOR 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt9',
      header: 'REFUND TAX AMOUNT 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd10',
      header: 'REFUND TAX INDICATOR 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt10',
      header: 'REFUND TAX AMOUNT 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd11',
      header: 'REFUND TAX INDICATOR 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt11',
      header: 'REFUND TAX AMOUNT 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd12',
      header: 'REFUND TAX INDICATOR 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt12',
      header: 'REFUND TAX AMOUNT 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd13',
      header: 'REFUND TAX INDICATOR 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt13',
      header: 'REFUND TAX AMOUNT 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd14',
      header: 'REFUND TAX INDICATOR 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt14',
      header: 'REFUND TAX AMOUNT 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd15',
      header: 'REFUND TAX INDICATOR 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt15',
      header: 'REFUND TAX AMOUNT 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd16',
      header: 'REFUND TAX INDICATOR 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt16',
      header: 'REFUND TAX AMOUNT 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd17',
      header: 'REFUND TAX INDICATOR 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt17',
      header: 'REFUND TAX AMOUNT 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd18',
      header: 'REFUND TAX INDICATOR 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt18',
      header: 'REFUND TAX AMOUNT 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd19',
      header: 'REFUND TAX INDICATOR 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt19',
      header: 'REFUND TAX AMOUNT 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd20',
      header: 'REFUND TAX INDICATOR 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt20',
      header: 'REFUND TAX AMOUNT 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd21',
      header: 'REFUND TAX INDICATOR 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt21',
      header: 'REFUND TAX AMOUNT 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd22',
      header: 'REFUND TAX INDICATOR 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt22',
      header: 'REFUND TAX AMOUNT 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd23',
      header: 'REFUND TAX INDICATOR 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt23',
      header: 'REFUND TAX AMOUNT 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd24',
      header: 'REFUND TAX INDICATOR 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt24',
      header: 'REFUND TAX AMOUNT 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd25',
      header: 'REFUND TAX INDICATOR 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt25',
      header: 'REFUND TAX AMOUNT 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd26',
      header: 'REFUND TAX INDICATOR 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt26',
      header: 'REFUND TAX AMOUNT 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd27',
      header: 'REFUND TAX INDICATOR 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt27',
      header: 'REFUND TAX AMOUNT 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd28',
      header: 'REFUND TAX INDICATOR 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt28',
      header: 'REFUND TAX AMOUNT 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd29',
      header: 'REFUND TAX INDICATOR 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt29',
      header: 'REFUND TAX AMOUNT 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd30',
      header: 'REFUND TAX INDICATOR 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt30',
      header: 'REFUND TAX AMOUNT 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
  ],
  flight: [
    {
      id: 'tktTktnoAl',
      header: 'TKT NO.（AIRLINEコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTktnoSn',
      header: 'TKT NO.（S/N）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktParentTktno',
      header: '親TKT NO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPaxName',
      header: 'PAX NAME',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPaxType',
      header: 'PAX TYPE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPtc',
      header: 'PTC（旅客タイプコード）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTktType',
      header: '券種',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktStatus',
      header: 'TKTステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareType',
      header: '運賃種別',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIata',
      header: 'IATAコード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCrs',
      header: 'GDS区分',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktPnr',
      header: 'PNR リファレンス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOrderId',
      header: 'ORDER ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktNdcFlg',
      header: 'NDCフラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktBookingPcc',
      header: '予約OFFICE ID/PCC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTktPcc',
      header: '発券OFFICE ID/PCC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIsuDate',
      header: '発券日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRcvDate',
      header: '受信日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRcvTime',
      header: '受信時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRstDate',
      header: '登録日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRstTime',
      header: '登録時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktDepDate',
      header: '出発日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIntDepDate',
      header: '国際線出発日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktMemo',
      header: 'メモ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee',
      header: 'AIRLINE名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej',
      header: 'AIRLINE名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lOdrCd',
      header: '受注コード（R）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lSecCd',
      header: 'セクションコード（S）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lMbrCd',
      header: '担当者コード（A）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lComCd',
      header: '法人コード（C）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lAreaCd',
      header: '方面コード（D）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lFreeCd',
      header: 'FREEコード（F）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lPayCd',
      header: '入金コード（M）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lTourCd',
      header: 'ツアーコード（T）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lKb1Cd',
      header: 'KBコード1（K）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lKb2Cd',
      header: 'KBコード2（B）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tkt7lEoCd',
      header: 'E/Oコード（E）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7OdrNamej',
      header: '受注コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7SecNamej',
      header: 'セクションコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'mbrNamee',
      header: '担当者名（英字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'mbrNamej',
      header: '担当者名（漢字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comNamee',
      header: '法人名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comNamej',
      header: '法人名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comSecNamee',
      header: '部署名（英字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'comSecNamej',
      header: '部署名（漢字）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7AreaNamej',
      header: '方面コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7PayNamej',
      header: '入金コード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7FreeNamej',
      header: 'FREEコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7Kb1Namej',
      header: 'KBコード1名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7Kb2Namej',
      header: 'KBコード2名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'l7EoNamej',
      header: 'E/Oコード名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGsCash',
      header: 'GROSS/CASH',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktGsCredit',
      header: 'GROSS/CREDIT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxCash',
      header: 'TAX/CASH',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxCredit',
      header: 'TAX/CREDIT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktCommRate',
      header: 'COMMISSION RATE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'commSum0',
      header: 'COMMISSION額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'commTax',
      header: 'COMMISSION額消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFareCalMode',
      header: 'FCMI',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareCalPricingInd',
      header: 'FCPI',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFareCalculation',
      header: 'FARE CALCULATION',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFare',
      header: 'FARE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTotal',
      header: 'TOTAL',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEqFapd',
      header: 'EQUIVALENT FARE PAID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEndRest',
      header: 'ENDORSEMENTS/RESTRICTIONS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktFormPay',
      header: 'FORM OF PAYMENT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'salesAmount',
      header: 'SALES AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktPrice',
      header: 'TKT原価',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'nsbNumber',
      header: 'NSB額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tkt2aNet',
      header: '2A NET額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'bspReqSum',
      header: 'BSP請求額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTokuiClm',
      header: '得意先請求金額（入力）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customSeikyuSum',
      header: '得意先請求金額（計算値）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customSum',
      header: '得意先金額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'customTax',
      header: '得意先消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'itsCompanyCom',
      header: '自社手数料',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNrid',
      header: 'NR-ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktItno',
      header: 'IT-NO',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVc',
      header: 'VC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCar',
      header: 'CAR',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktTourCd',
      header: 'TOUR CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGnr5Flg',
      header: 'GNR5対象フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktMonthStepoverFlg',
      header: '月跨ぎフラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktDetailIssuedFlg',
      header: 'チケット明細発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCustomerOutputFlg',
      header: 'お客様用発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktAgentCouponOutputFlg',
      header: 'AGENT COUPON発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCccfOutputFlg',
      header: 'CCCF発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefundNoticeOutputFlg',
      header: 'REFUND NOTICE発行済フラグ',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVoidId',
      header: 'VOID ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOvoId',
      header: 'オンラインVOID ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktVoidDate',
      header: 'VOID処理日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: true,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefId',
      header: 'REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktAtRefid',
      header: 'AUTO REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefType',
      header: 'REFUND区分（F/A/L/M）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefDate',
      header: 'REFUND処理日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktLesCntPen',
      header: 'CANCEL PENALTY',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktWaiverCode',
      header: 'WAIVER CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo1',
      header: 'COUPON1 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo2',
      header: 'COUPON2 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo3',
      header: 'COUPON3 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCpnNo4',
      header: 'COUPON4 REFUND ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktGrsFarePd',
      header: 'GROSS FARE PAID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFrUsed',
      header: 'GROSS FARE USED',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktGrsFareRef',
      header: 'GROSS FARE REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTaxRef',
      header: 'TAX REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktTotalAmt',
      header: 'TOTAL AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNetFareRef',
      header: 'NET FARE REFUND',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNetFare',
      header: 'NET FARE ADJUSTMENT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktNfa',
      header: 'TOTAL AMOUNT（NFA）',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktRefComm',
      header: 'REFUND COMMISSION額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktRefCommTax',
      header: 'REFUND COMMISSION額消費税',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktBalancePayable',
      header: 'BALANCE PAYABLE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktSac',
      header: 'SAC',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefMemo',
      header: 'REFUND MEMO',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRefPfc',
      header: 'REFUND PFC(XF) DATA',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReissueId',
      header: 'REISSUE ID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktOrgIsu',
      header: 'ORIGINAL ISSUE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktIsuEx',
      header: 'ISSUED IN EXCHANGE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCreditCom',
      header: 'クレジットカード会社コード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCardNum',
      header: 'クレジットカードNO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaExdate',
      header: 'CARD有効期限',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaMem',
      header: 'CARD会員名',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaAprid',
      header: 'CARD承認カードID',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaAprno',
      header: 'CARD承認番号',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktCaApramt',
      header: 'CARD承認金額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'tktFuncCd',
      header: '機能分類コード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks1',
      header: 'CARD RMKS1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks2',
      header: 'CARD RMKS2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktRmks3',
      header: 'CARD RMKS3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktConjData',
      header: 'CONJUNCTION TICKET DATA',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReasonIssuanceCode',
      header: 'REASON FOR ISSUANCE CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktReasonIssuanceDesc',
      header: 'REASON FOR ISSUANCE DESCRIPTION',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'tktEmdRmks',
      header: 'EMD TKT REMARKS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    // {
    //   id: 'tktEmdFlag',
    //   header: 'EMDフラグ',
    //   format: null,
    //   isReadOnly: true,
    //   width: 200,
    //   minWidth: 50,
    //   allowSorting: true,
    //   bulkEdit: false,
    //   disabled: false,
    //   align: 'left',
    // },
    {
      id: 'fltCpnNo',
      header: 'フライトクーポン番号',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltSeg',
      header: 'FLIGHT SEGMENT NO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltStopoverCode',
      header: 'STOP OVER CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltAirlineCd',
      header: 'FLIGHT AIRLINEコード',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFltNum',
      header: 'FLT NO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltClass',
      header: '搭乗クラス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFrom',
      header: '出発地',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltTo',
      header: '到着地',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltFareBasis',
      header: 'FARE BASIS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardDate',
      header: '搭乗日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBoardTime',
      header: '搭乗時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrDate',
      header: '到着日',
      dataType: 'Date',
      format: 'yyyy/MM/dd',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltArrTime',
      header: '到着時間',
      dataType: 'Date',
      format: 'HH:mm',
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNva',
      header: 'NVA',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltNvb',
      header: 'NVB',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltBagAlw',
      header: 'BAGGAGE ALLOWANCE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'supportSum',
      header: 'サポート額',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'fltReservationStatus',
      header: '予約ステータス',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'fltIndByteTkt',
      header: 'フライト区間種別',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamee',
      header: 'FLIGHT AIRLINE名(英字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'alnNamej',
      header: 'FLIGHT AIRLINE名(漢字)',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxCount',
      header: 'TAX COUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'emdCpnNo',
      header: 'EMD CPN NO.',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCode',
      header: 'REASON FOR ISSUANCE SUB CODE',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdReasonIssuanceSubCodeDesc',
      header: 'REASON FOR ISSUANCE SUB CODE DESCRIPTION',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnFieldFeeAmt',
      header: 'COUPON FILED FEE AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnValueAmt',
      header: 'COUPON VALUE AMOUNT',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdConsumedIssuaranceInd',
      header: 'CONSUMED AT ISSUANCE INDICATOR',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'emdCpnRmks',
      header: 'COUPON REMARKS',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt1',
      header: 'TAX AMOUNT 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd1',
      header: 'TAX INDICATOR 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt2',
      header: 'TAX AMOUNT 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd2',
      header: 'TAX INDICATOR 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt3',
      header: 'TAX AMOUNT 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd3',
      header: 'TAX INDICATOR 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt4',
      header: 'TAX AMOUNT 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd4',
      header: 'TAX INDICATOR 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt5',
      header: 'TAX AMOUNT 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd5',
      header: 'TAX INDICATOR 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt6',
      header: 'TAX AMOUNT 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd6',
      header: 'TAX INDICATOR 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt7',
      header: 'TAX AMOUNT 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd7',
      header: 'TAX INDICATOR 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt8',
      header: 'TAX AMOUNT 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd8',
      header: 'TAX INDICATOR 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt9',
      header: 'TAX AMOUNT 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd9',
      header: 'TAX INDICATOR 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt10',
      header: 'TAX AMOUNT 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd10',
      header: 'TAX INDICATOR 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt11',
      header: 'TAX AMOUNT 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd11',
      header: 'TAX INDICATOR 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt12',
      header: 'TAX AMOUNT 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd12',
      header: 'TAX INDICATOR 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt13',
      header: 'TAX AMOUNT 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd13',
      header: 'TAX INDICATOR 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt14',
      header: 'TAX AMOUNT 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd14',
      header: 'TAX INDICATOR 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt15',
      header: 'TAX AMOUNT 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd15',
      header: 'TAX INDICATOR 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt16',
      header: 'TAX AMOUNT 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd16',
      header: 'TAX INDICATOR 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt17',
      header: 'TAX AMOUNT 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd17',
      header: 'TAX INDICATOR 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt18',
      header: 'TAX AMOUNT 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd18',
      header: 'TAX INDICATOR 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt19',
      header: 'TAX AMOUNT 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd19',
      header: 'TAX INDICATOR 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt20',
      header: 'TAX AMOUNT 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd20',
      header: 'TAX INDICATOR 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt21',
      header: 'TAX AMOUNT 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd21',
      header: 'TAX INDICATOR 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt22',
      header: 'TAX AMOUNT 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd22',
      header: 'TAX INDICATOR 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt23',
      header: 'TAX AMOUNT 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd23',
      header: 'TAX INDICATOR 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt24',
      header: 'TAX AMOUNT 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd24',
      header: 'TAX INDICATOR 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt25',
      header: 'TAX AMOUNT 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd25',
      header: 'TAX INDICATOR 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt26',
      header: 'TAX AMOUNT 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd26',
      header: 'TAX INDICATOR 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt27',
      header: 'TAX AMOUNT 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd27',
      header: 'TAX INDICATOR 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt28',
      header: 'TAX AMOUNT 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd28',
      header: 'TAX INDICATOR 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt29',
      header: 'TAX AMOUNT 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd29',
      header: 'TAX INDICATOR 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'taxTaxAmt30',
      header: 'TAX AMOUNT 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'taxTaxInd30',
      header: 'TAX INDICATOR 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxInd1',
      header: 'REFUND TAX INDICATOR 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt1',
      header: 'REFUND TAX AMOUNT 1',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd2',
      header: 'REFUND TAX INDICATOR 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt2',
      header: 'REFUND TAX AMOUNT 2',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd3',
      header: 'REFUND TAX INDICATOR 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt3',
      header: 'REFUND TAX AMOUNT 3',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd4',
      header: 'REFUND TAX INDICATOR 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt4',
      header: 'REFUND TAX AMOUNT 4',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd5',
      header: 'REFUND TAX INDICATOR 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt5',
      header: 'REFUND TAX AMOUNT 5',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd6',
      header: 'REFUND TAX INDICATOR 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt6',
      header: 'REFUND TAX AMOUNT 6',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd7',
      header: 'REFUND TAX INDICATOR 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt7',
      header: 'REFUND TAX AMOUNT 7',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd8',
      header: 'REFUND TAX INDICATOR 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt8',
      header: 'REFUND TAX AMOUNT 8',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd9',
      header: 'REFUND TAX INDICATOR 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt9',
      header: 'REFUND TAX AMOUNT 9',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd10',
      header: 'REFUND TAX INDICATOR 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt10',
      header: 'REFUND TAX AMOUNT 10',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd11',
      header: 'REFUND TAX INDICATOR 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt11',
      header: 'REFUND TAX AMOUNT 11',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd12',
      header: 'REFUND TAX INDICATOR 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt12',
      header: 'REFUND TAX AMOUNT 12',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd13',
      header: 'REFUND TAX INDICATOR 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt13',
      header: 'REFUND TAX AMOUNT 13',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd14',
      header: 'REFUND TAX INDICATOR 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt14',
      header: 'REFUND TAX AMOUNT 14',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd15',
      header: 'REFUND TAX INDICATOR 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt15',
      header: 'REFUND TAX AMOUNT 15',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd16',
      header: 'REFUND TAX INDICATOR 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt16',
      header: 'REFUND TAX AMOUNT 16',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd17',
      header: 'REFUND TAX INDICATOR 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt17',
      header: 'REFUND TAX AMOUNT 17',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd18',
      header: 'REFUND TAX INDICATOR 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt18',
      header: 'REFUND TAX AMOUNT 18',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd19',
      header: 'REFUND TAX INDICATOR 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt19',
      header: 'REFUND TAX AMOUNT 19',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd20',
      header: 'REFUND TAX INDICATOR 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt20',
      header: 'REFUND TAX AMOUNT 20',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd21',
      header: 'REFUND TAX INDICATOR 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt21',
      header: 'REFUND TAX AMOUNT 21',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd22',
      header: 'REFUND TAX INDICATOR 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt22',
      header: 'REFUND TAX AMOUNT 22',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd23',
      header: 'REFUND TAX INDICATOR 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt23',
      header: 'REFUND TAX AMOUNT 23',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd24',
      header: 'REFUND TAX INDICATOR 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt24',
      header: 'REFUND TAX AMOUNT 24',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd25',
      header: 'REFUND TAX INDICATOR 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt25',
      header: 'REFUND TAX AMOUNT 25',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd26',
      header: 'REFUND TAX INDICATOR 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt26',
      header: 'REFUND TAX AMOUNT 26',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd27',
      header: 'REFUND TAX INDICATOR 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt27',
      header: 'REFUND TAX AMOUNT 27',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd28',
      header: 'REFUND TAX INDICATOR 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt28',
      header: 'REFUND TAX AMOUNT 28',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd29',
      header: 'REFUND TAX INDICATOR 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt29',
      header: 'REFUND TAX AMOUNT 29',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
    {
      id: 'rtxRftaxInd30',
      header: 'REFUND TAX INDICATOR 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'left',
    },
    {
      id: 'rtxRftaxAmt30',
      header: 'REFUND TAX AMOUNT 30',
      format: null,
      isReadOnly: true,
      width: 200,
      minWidth: 50,
      allowSorting: true,
      bulkEdit: false,
      disabled: false,
      align: 'right',
    },
  ]
}
