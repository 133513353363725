import _ from 'lodash';
import { TITLE } from 'constants/title';
import { CLIENT_DOWNLOAD_URL, FILE_UPLODER_DOWNLOAD_URL } from 'constants/index';

export const LEFT_MENU = {
  main: {
    name: 'トップメニュー',
    icon: {
      name: 'home',
      style: '-outlined',
    },
    link: TITLE.main.link,
    params: {init: true},
  },
}

export const CENTER_MENU = {
  ticket: {
    name: 'データ検索',
    icon: {
      name: 'manage_search',
      style: '',
    },
    sub: [
      {
        header: '発券データ検索',
        link: TITLE.ticketSearch.link,
        params: {init: true},
      },
      {
        header: '発券データ登録・修正',
        link: TITLE.ticketEdit.mainLink + '/_new',
        params: {init: true},
        auth: 'auth010',
      },
    ]
  },
  ticketIO: {
    name: 'データ入出力',
    icon: {
      name: 'import_export',
      style: '',
    },
    auth: 'auth300',
    sub: [
      {
        header: '発券データ入力',
        link: TITLE.ticketImport.link,
        params: {init: true},
        auth: 'auth030',
      },
      {
        header: '発券データ出力',
        link: TITLE.ticketExport.link,
        params: {init: true},
        auth: 'auth040',
      },
      {
        header: 'EXCELデータ出力',
        link: TITLE.ticketExcel.link,
        params: {init: true},
        auth: 'auth050',
      },
    ]
  },
  pivot: {
    name: 'URI-Pro BI',
    icon: {
      name: 'assessment',
      style: '-outlined',
    },
    link: TITLE.pivotSearch.link,
    params: {init: true},
    auth: 'auth060',
  },
  report: {
    name: '固定帳票出力',
    icon: {
      name: 'source',
      style: '-outlined',
    },
    link: TITLE.report.link,
    params: {init: true},
    auth: 'auth070',
  },
  timer: {
    name: 'タイマー設定',
    icon: {
      name: 'timer',
      style: '-outlined',
    },
    link: TITLE.timer.link,
    params: {init: true},
    auth: 'auth100',
  },
  maintenance: {
    name: 'メンテナンス',
    icon: {
      name: 'settings',
      style: '-outlined',
    },
    sub: [
      {
        header: 'テーブルメンテナンス',
        items: [
          {
            header: 'アカウントコード共通(受注コード)',
            link: `${TITLE.master.mainLink}/order`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(方面コード)',
            link: `${TITLE.master.mainLink}/area`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(セクションコード)',
            link: `${TITLE.master.mainLink}/section`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(FREEコード)',
            link: `${TITLE.master.mainLink}/free`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(ツアーコード)',
            link: `${TITLE.master.mainLink}/tour`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(KBコード1)',
            link: `${TITLE.master.mainLink}/kb1`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(KBコード2)',
            link: `${TITLE.master.mainLink}/kb2`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(入金コード)',
            link: `${TITLE.master.mainLink}/pay`,
            params: {init: true},
          },
          {
            header: 'アカウントコード共通(E/Oコード)',
            link: `${TITLE.master.mainLink}/eo`,
            params: {init: true},
          },
          {
            header: '法人コード',
            link: `${TITLE.master.mainLink}/company`,
            params: {init: true},
          },
          {
            header: '担当者コード',
            link: `${TITLE.master.mainLink}/member`,
            params: {init: true},
          },
          {
            header: 'PRODUCTION',
            link: `${TITLE.master.mainLink}/production`,
            params: {init: true},
          },
          {
            header: 'DECODE',
            link: `${TITLE.master.mainLink}/decode`,
            params: {init: true},
          },
          {
            header: '運賃種別判定',
            link: `${TITLE.master.mainLink}/fare`,
            params: {init: true},
          },
        ]
      },
      {
        header: 'システムメンテナンス',
        link: `${TITLE.system.mainLink}/travel`,
        params: {init: true},
      },
      {
        header: '処理結果一覧',
        link: TITLE.process.link,
        params: {init: true, caller: 'menu'},
      },
      {
        header: '保守員ログイン',
        link: TITLE.mntLogin.link,
        params: {init: true},
      },
      {
        header: '保守ツール',
        link: TITLE.mntTool.link,
        params: {init: true},
      },
      {
        header: 'URI-Pro File Uploaderインストール',
        link: FILE_UPLODER_DOWNLOAD_URL,
      },
      {
        header: 'Plusクライアントインストール',
        link: CLIENT_DOWNLOAD_URL,
        auth: 'authCli',
      },
    ]
  },
  info: {
    name: 'お知らせ',
    icon: {
      name: 'speaker_notes',
      style: '-outlined',
    },
    link: TITLE.info.link,
    params: {init: true},
  },
}

export const RIGHT_MENU = {
  message: {
    name: 'message',
    icon: {
      name: 'feedback',
      style: '-outlined',
    },
  },
  alert: {
    name: '',
    icon: {
      name: 'notifications',
      style: '-outlined',
    },
    className: 'menu-alert',
    link: TITLE.process.link,
    params: {init: true, caller: 'alert'},
  },
  user: {
    name: 'ユーザー',
    icon: {
      name: 'account_circle',
      style: '-outlined',
    },
    className: 'menu-user',
  },
  end: {
    name: '業務終了',
    icon: {
      name: 'logout',
      style: '',
    },
    link: TITLE.logout.link,
    params: {init: true},
  },
}
export const MAIN_MENU = [
  [
    {
      name: '発券データ検索',
      icon: {
        name: 'manage_search',
        style: '',
      },
      link: TITLE.ticketSearch.link,
      params: {init: true},
    },
    {
      name: '発券データ登録・修正',
      icon: {
        name: 'edit_note',
        style: '-outlined',
      },
      link: TITLE.ticketEdit.mainLink + '/_new',
      params: {init: true},
      auth: 'auth010',
    },
  ],
  [
    {
      name: '発券データ入力',
      icon: {
        name: 'file_upload',
        style: '-outlined',
      },
      link: TITLE.ticketImport.link,
      params: {init: true},
      auth: 'auth030',
    },
    {
      name: '発券データ出力',
      icon: {
        name: 'file_download',
        style: '-outlined',
      },
      link: TITLE.ticketExport.link,
      params: {init: true},
      auth: 'auth040',
    },
    {
      name: 'EXCELデータ出力',
      icon: {
        name: 'assignment_returned',
        style: '-outlined',
      },
      link: TITLE.ticketExcel.link,
      params: {init: true},
      auth: 'auth050',
    },
    {
      name: 'PRISM用データ出力',
      icon: {
        name: 'cloud_download',
        style: '-outlined',
      },
      link: TITLE.prism.link,
      params: {init: true},
      auth: 'auth120',
    },
  ],
  [
    {
      name: 'URI-Pro BI',
      icon: {
        name: 'assessment',
        style: '-outlined',
      },
      link: TITLE.pivotSearch.link,
      params: {init: true},
      auth: 'auth060',
    },
    {
      name: '固定帳票出力',
      icon: {
        name: 'source',
        style: '-outlined',
      },
      link: TITLE.report.link,
      params: {init: true},
      auth: 'auth070',
    },
    {
      name: 'タイマー設定',
      icon: {
        name: 'timer',
        style: '-outlined',
      },
      link: TITLE.timer.link,
      params: {init: true},
      auth: 'auth100',
    },
  ],
  [
    {
      name: 'テーブルメンテナンス',
      icon: {
        name: 'settings',
        style: '-outlined',
      },
      link: TITLE.master.mainLink,
      params: {init: true},
    },
    {
      name: 'システムメンテナンス',
      icon: {
        name: 'settings',
        style: '-outlined',
      },
      link: TITLE.system.mainLink + '/travel',
      params: {init: true},
    },
    {
      name: 'データメンテナンス',
      icon: {
        name: 'settings',
        style: '-outlined',
      },
      link: TITLE.system.mainLink + '/maint',
      params: {init: true},
      auth: 'auth110',
    },
    {
      name: '処理結果一覧',
      icon: {
        name: 'timer',
        style: '-outlined',
      },
      link: TITLE.process.link,
      params: {init: true, caller: 'menu'},
    },
  ]
]
