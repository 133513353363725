import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AppAction } from 'ducks/App';
import { TktImportAction } from 'ducks/TktImport';
import * as Common from 'components/common';
import { Title, Card, ProcessingPopup } from 'components';
import { 
  Col,
  Row,
  Container,
  Table,
  Form,
  Button,
  Toast,
} from 'react-bootstrap';
import { entryCheck, convert } from "lib";
import _ from 'lodash';

import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import * as wjGrid from "@grapecity/wijmo.react.grid";
import * as wjInput from '@grapecity/wijmo.react.input';
import { format } from '@grapecity/wijmo';
import { CollectionView, DataType, toggleClass } from "@grapecity/wijmo";
import { TITLE } from 'constants/title';
import { MESSAGE } from 'constants/message';
import { COND_ITEMS, RADIOS, CHECKS, NUMBER } from 'constants/search';
import { IFN_FORMAT, IMPORT_MESSAGE, LIST_ITEMS, IN_INPUT_TYPE } from 'constants/ticket';
import { MasterPopup } from 'components/Master/modal/MasterPopup';
import { MasterAction } from 'ducks/Master';
import { TicketApi, LoginApi } from 'webapi'
import { FileUploaderInstallPopup } from './modal/FileUploaderInstallPopup';

export const TicketImportComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const tktImport = useSelector(state => state.TktImport);
  
  // const history = useHistory();
  // const location = useLocation();

  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const tktImportAction = bindActionCreators(TktImportAction, dispatch);

  const [amsPaths, setAmsPaths] = React.useState(null)
  const [ifnPaths, setIfnPaths] = React.useState(null)
  const [trpPaths, setTrpPaths] = React.useState(null)
  const [sbrPaths, setSbrPaths] = React.useState(null)
  const [othPaths, setOthPaths] = React.useState(null)
  const [gnr5Paths, setGnr5Paths] = React.useState(null)
  const [fileInvalid, setPathInvalid] = React.useState({
    fileAms: false,
    fileIfn: false,
    fileTrp: false,
    fileSbr: false,
    fileOth: false,
    fileGnr5: false,
  })
  const [procPopup, setProcPopup] = React.useState(null)
  const [allSelected, setAllSelected] = React.useState('0')
  const [grid, setGrid] = React.useState(null)
  const [isSubPathChange, setIsSubPathChange] = React.useState(null)
  const [fileUploaderInstall, setFileUploaderInstall] = React.useState(null)
  const [fileUploaderInstallInit, setFileUploaderInstallInit] = React.useState(false)

  React.useEffect(() => {
    if (props.isModal && !props.isOpen) return
    if (!fileUploaderInstallInit) return
    if (localStorage.getItem('fileUploaderModalFg') == 'true') return
    fileUploaderInstall.show(true, (sender) => {
    })
  }, [fileUploaderInstallInit])

  React.useEffect(() => {
    if (props.isModal && !props.isOpen) return
    appAction.showProcessing({isProcessing: true})
    appAction.setMessage('')
    if (!props.isModal) {
      LoginApi.getKey(() => {})
      .then(res => {
        appAction.setInputApp({isUnloadCheck: false})
        const linkEl = document.createElement('a');
        linkEl.href = 'uriprofileuploader:'+res.payload.key;
        document.body.appendChild(linkEl);
        linkEl.click();
        setTimeout(() => {
          appAction.setInputApp({isUnloadCheck: true})
          linkEl.parentNode.removeChild(linkEl);
        })
      })
      .catch(() =>{})
      .finally(() => {})
    }

    ticketImportInit()
  }, [props.isOpen])

  const ticketImportInit = () => {
    TicketApi.inputSearch({
      isTimerAccess: props.isModal
    })
    .then(res => {
      appAction.setBatchResult({processingSeq: ''})
      const obj = {}
      obj.iniDto = res.payload.iniDto
      // obj.errLogList = res.payload.errLogList
      _.forEach(tktImport, (v, id) => {
        if (v && !_.isUndefined(v.value)) {
          if (id !== 'tktInAmsPath' && id !== 'tktInIfnPath' && id !== 'tktInTrpPath' && id !== 'tktInSbrPath' && id !== 'tktInOthPath' && id !== 'tktInGnrPath') {
            obj[id] = {
              ...v,
              original: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
              value: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
              text: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
            }
          } else {
            obj[id] = {
              ...v,
              // original: props.isModal?obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'':'',
              // value: props.isModal?obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'':'',
              // text: props.isModal?obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'':'',
              original: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
              value: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
              text: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'',
            }
          }
          if (id === 'tktInInputType') {
            obj[id] = {
              ...v,
              original: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'0',
              value: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'0',
              text: obj.iniDto && obj.iniDto[id]?obj.iniDto[id]:'0',
            }
          }
        }
      })
      
      _.forEach(['Ifn', 'Trp', 'Sbr'], (crs) => {
        if (login[`auth${crs}`] === '1') {
          obj[`tktIn${crs}Flg`].disabled = false
          obj[`tktIn${crs}Path`].disabled = false
          obj[`tktIn${crs}S3Path`].disabled = false
        } else {
          obj[`tktIn${crs}Flg`].disabled = true
          obj[`tktIn${crs}Flg`].original = '0'
          obj[`tktIn${crs}Flg`].value = '0'
          obj[`tktIn${crs}Flg`].text = '0'
          obj[`tktIn${crs}Path`].disabled = true
          obj[`tktIn${crs}Path`].original = ''
          obj[`tktIn${crs}Path`].value = ''
          obj[`tktIn${crs}Path`].text = ''
          obj[`tktIn${crs}S3Path`].disabled = true
          obj[`tktIn${crs}S3Path`].original = ''
          obj[`tktIn${crs}S3Path`].value = ''
          obj[`tktIn${crs}S3Path`].text = ''
        }
      })

      setAmsPaths(obj['tktInAmsPath'].text !== ""?obj['tktInAmsPath'].text:null)
      setIfnPaths(obj['tktInIfnPath'].text !== ""?obj['tktInIfnPath'].text:null)
      setTrpPaths(obj['tktInTrpPath'].text !== ""?obj['tktInTrpPath'].text:null)
      setSbrPaths(obj['tktInSbrPath'].text !== ""?obj['tktInSbrPath'].text:null)
      setOthPaths(obj['tktInOthPath'].text !== ""?obj['tktInOthPath'].text:null)
      setGnr5Paths(obj['tktInGnr5Path'].text !== ""?obj['tktInGnr5Path'].text:null)

      tktImportAction.setInputTktImport(obj)
      // const obj = {}
      // appAction.setMessage({message: res.payload.messageList})
      // _.forEach(master, (v, k) => {
      //   if (k.endsWith('List')) obj[k] = []
      // })

      // if (res.payload.code === '000' || res.payload.code === '100') {
      //   obj.selDate = res.payload.selDate
      //   obj[`${type}List`] = res.payload.masterCodeList
      // }
      // masterAction.setInputMaster({...obj})
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    if (isSubPathChange) {
      // console.log(isSubPathChange)
      const e = isSubPathChange.event
      const crs = isSubPathChange.crs
      let item = e[`tktIn${crs}S3Path`].text
      if (item && !_.find(tktImport.subPathList, { 'name': item })) {
        const list = _.concat(tktImport.subPathList, [{
          id: '' + tktImport.subPathList.length, name: item}
        ])
        tktImportAction.setInputTktImport({subPathList: list})
      }
    }
  }, [isSubPathChange])

  const generateComponent = (crs, crsName, setPaths) => {
    return (
      <tr key={crs} className={(login[`auth${crs}`] && login[`auth${crs}`] !== '1')?'disabled':''}>
        <td className='align-middle'>
          <Common.Check
            attrs={{
              id: tktImport[`tktIn${crs}Flg`].id,
              label: '',
              text: tktImport[`tktIn${crs}Flg`].text,
              // disabled: tktImport[`tktIn${crs}Flg`].disabled,
                disabled: (login[`auth${crs}`] && login[`auth${crs}`] !== '1')
            }}
            onChange={(e) => tktImportAction.changeInputTktImport(e)}
          />
        </td>
        <td className='align-middle'>{crsName}</td>
        {!props.isModal &&
          <td className={`align-middle ${fileInvalid[`file${crs}`] ? 'text-danger' : ''}`}>
            {fileInvalid[`file${crs}`]?'ファイルを選択してください。':tktImport[`tktIn${crs}Path`].text}
          </td>
        }
        {props.isModal && tktImport.tktInInputType.text === '0' &&
          <td className='align-middle'>
            <Common.Text
              attrs={{
                ...tktImport[`tktIn${crs}Path`],
                label: '_none',
                // disabled: tktImport[`tktIn${crs}Path`].disabled,
                disabled: (login[`auth${crs}`] && login[`auth${crs}`] !== '1')
              }}
              onBlur={(e) => tktImportAction.changeInputTktImport(e)}
              group={{
                ...props.group,
                className: 'w-100 mr-0',
              }}
            />
          </td>
        }
        {props.isModal && tktImport.tktInInputType.text === '1' &&
          <td className='align-middle'>
            <Common.AutoComplete
              attrs={{
                ...tktImport[`tktIn${crs}S3Path`],
                label: '_none',
                // disabled: tktImport[`tktIn${crs}S3Path`].disabled,
                disabled: (login[`auth${crs}`] && login[`auth${crs}`] !== '1')
              }}
              delay={100}
              displayMemberPath='name'
              itemsSource={tktImport.subPathList}
              onBlur={(e) => {
                tktImportAction.changeInputTktImport(e)
                setIsSubPathChange({crs: crs, event: e})
              }}
              group={{
                ...props.group,
                className: 'w-100 mr-0',
                textwidth: 'w-100 mr-0',
              }}
            />
          </td>
        }
        {/*  */}
        {/* <td className={props.isModal?'d-none':'p-0 align-middle'}>
          <label className='import-button'>
            <span className=''>
              <span className="material-icons-outlined md blue">upload_file</span>
            </span>
            <input
              id='file'
              type='file'
              hidden
              disabled={(login[`auth${crs}`] && login[`auth${crs}`] !== '1')}
              onChange={(e) => {
                setPaths(e.currentTarget.files)
                fileInvalid[`file${crs}`] = false
                const fname = e.currentTarget.files.length > 0?e.currentTarget.files[0].name:''
                tktImportAction.changeInputTktImport(convert.getTarget(tktImport[`tktIn${crs}Path`], 'text', fname))
                tktImportAction.changeInputTktImport(convert.getTarget(tktImport[`tktIn${crs}Flg`], 'text', fname?'1':'0'))
              }}
            />
          </label>
        </td> */}
        <td className={props.isModal?'d-none':'align-middle'}>
          <Button
            className='button-blue ml-auto width-max-content'
            disabled={app.isProcessing}
            onClick={() => execDelete(crs)}
          >
            削除
          </Button>
        </td>
      </tr>
    )
  }

  const execUpload = () => {
    const tktInAmsFlg = tktImport.tktInAmsFlg.text
    const tktInIfnFlg = login.authIfn==='1'?tktImport.tktInIfnFlg.text:'0'
    const tktInTrpFlg = login.authTrp==='1'?tktImport.tktInTrpFlg.text:'0'
    const tktInSbrFlg = login.authSbr==='1'?tktImport.tktInSbrFlg.text:'0'
    const tktInOthFlg = tktImport.tktInOthFlg.text
    const tktInGnr5Flg = tktImport.tktInGnr5Flg.text

    setPathInvalid({
      fileAms: (tktInAmsFlg === '1' && !amsPaths),
      fileIfn: (tktInIfnFlg === '1' && !ifnPaths),
      fileTrp: (tktInTrpFlg === '1' && !trpPaths),
      fileSbr: (tktInSbrFlg === '1' && !sbrPaths),
      fileOth: (tktInOthFlg === '1' && !othPaths),
      fileGnr5: (tktInGnr5Flg === '1' && !gnr5Paths),
    })
    if ( tktInAmsFlg !== '1'
      && tktInIfnFlg !== '1'
      && tktInTrpFlg !== '1'
      && tktInSbrFlg !== '1'
      && tktInOthFlg !== '1'
      && tktInGnr5Flg !== '1') {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    if ((tktInAmsFlg === '1' && !amsPaths)
      ||(tktInIfnFlg === '1' && !ifnPaths)
      ||(tktInTrpFlg === '1' && !trpPaths)
      ||(tktInSbrFlg === '1' && !sbrPaths)
      ||(tktInOthFlg === '1' && !othPaths)
      ||(tktInGnr5Flg === '1' && !gnr5Paths)) return

    const dto = {
      execType: '0',
      iniDto: {
        tktInAmsFlg: tktInAmsFlg,
        tktInAmsPath: amsPaths,
        tktInIfnFlg: tktInIfnFlg,
        // tktInIfnFormat: tktImport.tktInIfnFormat.text,
        tktInIfnPath: ifnPaths,
        tktInTrpFlg: tktInTrpFlg,
        tktInTrpPath: trpPaths,
        tktInSbrFlg: tktInSbrFlg,
        tktInSbrPath: sbrPaths,
        tktInOthFlg: tktInOthFlg,
        tktInOthPath: othPaths,
        tktInGnr5Flg: tktInGnr5Flg,
        tktInGnr5Path: gnr5Paths,
        tktInInputType: '0',
        tktInIniMbrcd: login.loginCd,
        cmnMbrcd: login.loginCd,
      }
    }

    const formData = new FormData();
    formData.append('dto', new Blob(
      [JSON.stringify(dto)], { type: 'application/json' }
    ));

    if (tktInAmsFlg === '1') formData.append('tktInAmsFileData', amsPaths[0]);
    if (tktInIfnFlg === '1') formData.append('tktInIfnFileData', ifnPaths[0]);
    if (tktInTrpFlg === '1') formData.append('tktInTrpFileData', trpPaths[0]);
    if (tktInSbrFlg === '1') formData.append('tktInSbrFileData', sbrPaths[0]);
    if (tktInOthFlg === '1') formData.append('tktInOthFileData', othPaths[0]);
    if (tktInGnr5Flg === '1') formData.append('tktInGnr5FileData', gnr5Paths[0]);
    
    appAction.showProcessing({isProcessing: true})
    tktImportAction.setInputTktImport({errLogList: []})
    TicketApi.inputUpload(formData).then((res) => {
      if (!res.payload) {
        appAction.setMessage({message: [{
          id: MESSAGE.changeFile().id,
          message: MESSAGE.changeFile().text,
          type: MESSAGE.changeFile().icon,
        }]})
        return
      }

      if (res.payload.code === '000') {
        appAction.setBatchResult({processingSeq: res.payload.processingSeq})
        procPopup.show(true, (sender) => {
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            ticketImportInit()
            // console.log('ok')
          } else {
            // console.log('cancel')
          }
        })
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(err =>{})
    .finally(() => {
      _.forEach(document.querySelectorAll('#file'), el => {
        el.value = ''
      })
      // setAmsPaths(null)
      // setIfnPaths(null)
      // setTrpPaths(null)
      // setSbrPaths(null)
      // setOthPaths(null)
      // setGnr5Paths(null)
      // let obj = {}
      // _.forEach(['Ams', 'Ifn', 'Trp', 'Sbr', 'Oth', 'Gnr5'], (crs) => {
      //   obj[`tktIn${crs}Path`] = {
      //     ...tktImport[`tktIn${crs}Path`],
      //     original: '',
      //     value: '',
      //     text: '',
      //   }
      // })
      // tktImportAction.setInputTktImport(obj)
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execCsv = () => {
    const downloadFileName = 'input-error.csv'
    appAction.showProcessing({isProcessing: true})
    TicketApi.inputCsv({
      downloadFileName: downloadFileName,
      errLogList: tktImport.errLogList,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        const blob = new Blob([String.fromCharCode(0xFEFF), res.payload], {type: 'text/csv'})
        if (window.navigator.msSaveOrOpenBlob) {
          // for IE,Edge
          window.navigator.msSaveOrOpenBlob(blob, downloadFileName);
        } else {
          // for chrome, firefox
          const url = URL.createObjectURL(blob);
          const linkEl = document.createElement('a');
          linkEl.href = url;
          linkEl.setAttribute('download', downloadFileName);
          document.body.appendChild(linkEl);
          linkEl.click();
          URL.revokeObjectURL(url);
          linkEl.parentNode.removeChild(linkEl);
        }
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  React.useEffect(() => {
    if ( tktImport.tktInAmsFlg.text === '1'
      && (tktImport.tktInIfnFlg.text === '1' || login.authIfn!=='1')
      && (tktImport.tktInTrpFlg.text === '1' || login.authTrp!=='1')
      && (tktImport.tktInSbrFlg.text === '1' || login.authSbr!=='1')
      && tktImport.tktInOthFlg.text === '1'
      && tktImport.tktInGnr5Flg.text === '1') {
      setAllSelected('1')
    } else {
      setAllSelected('0')
    }
  }, [tktImport.tktInAmsFlg.text, tktImport.tktInIfnFlg.text, tktImport.tktInTrpFlg.text, tktImport.tktInSbrFlg.text, tktImport.tktInOthFlg.text, tktImport.tktInGnr5Flg.text])

  React.useEffect(() => {
    if (props.isModal) {
      const obj = {}
      let crsList = []
      crsList.push('Ams')
      if (login.authIfn==='1') crsList.push('Ifn')
      if (login.authTrp==='1') crsList.push('Trp')
      if (login.authSbr==='1') crsList.push('Sbr')
      crsList.push('Oth')
      crsList.push('Gnr5')

      _.forEach(crsList, (crs) => {
        obj[`tktIn${crs}Path`] = {
          ...tktImport[`tktIn${crs}Path`],
          required: tktImport.tktInInputType.text === '0' && tktImport[`tktIn${crs}Flg`].text === '1'
        }
        obj[`tktIn${crs}S3Path`] = {
          ...tktImport[`tktIn${crs}S3Path`],
          required: tktImport.tktInInputType.text === '1' && tktImport[`tktIn${crs}Flg`].text === '1'
        }
      })
      // 
      tktImportAction.setInputTktImport(obj)
    }
  }, [tktImport.tktInAmsFlg.text, tktImport.tktInIfnFlg.text, tktImport.tktInTrpFlg.text, tktImport.tktInSbrFlg.text, tktImport.tktInOthFlg.text, tktImport.tktInGnr5Flg.text, tktImport.tktInInputType.text])

  React.useEffect(() => {
    // if (tktImport.tktInInputType.text === tktImport.tktInInputType.original) {
    //   voihvb
    // }
    TicketApi.getSftpSubPathList()
    .then(res => {
      let subPathList = [{id: '0', name: ''}]
      _.forEach(res.payload.subPathList, (r, i) => {
        subPathList.push({
          id: '' + (i + 1),
          name: r,
        })
      })
      _.forEach(['Ams', 'Ifn', 'Trp', 'Sbr', 'Oth', 'Gnr5'], (crs, i) => {
        let item = tktImport[`tktIn${crs}S3Path`].text
        
        if (item && !_.find(subPathList, { 'name': item })) {
          subPathList.push({
            id: '' + ('' + (subPathList.length + 1)),
            name: tktImport[`tktIn${crs}S3Path`].text,
          })
        }
      })

      tktImportAction.setInputTktImport({
        subPathList: subPathList
      })
    })
    .catch(() => {})
    .finally(() => {})
  }, [tktImport.tktInInputType.text])

  const execSetting = () => {
    const flg = {
      tktInAmsFlg: tktImport.tktInAmsFlg.text,
      tktInIfnFlg: login.authIfn==='1'?tktImport.tktInIfnFlg.text:'0',
      tktInTrpFlg: login.authTrp==='1'?tktImport.tktInTrpFlg.text:'0',
      tktInSbrFlg: login.authSbr==='1'?tktImport.tktInSbrFlg.text:'0',
      tktInOthFlg: tktImport.tktInOthFlg.text,
      tktInGnr5Flg: tktImport.tktInGnr5Flg.text,
    }
    let entry ={}
    let hasError = false
    _.forEach(_.filter(tktImport, (v, k) => k.endsWith('Path')), r => {
      entry[r.id] = entryCheck(tktImport, convert.getTarget(tktImport[r.id], 'text', tktImport[r.id].text))[r.id]
      hasError = hasError || entry[r.id].isInvalid
    })
    tktImportAction.setInputTktImport(entry)
    if (hasError) return

    if ( flg.tktInAmsFlg !== '1'
      && flg.tktInIfnFlg !== '1'
      && flg.tktInTrpFlg !== '1'
      && flg.tktInSbrFlg !== '1'
      && flg.tktInOthFlg !== '1'
      && flg.tktInGnr5Flg !== '1') {
      appAction.setMessage({message: [{
        id: MESSAGE.targetNoSelect().id,
        message: MESSAGE.targetNoSelect().text,
        type: MESSAGE.targetNoSelect().icon,
      }]})
      return
    }
    
    const dto = {
      isTimerAccess: true,
      iniDto: {
        tktInInputType: (login.auth032==='1' || login.authFtp==='1')?tktImport.tktInInputType.text:''
      }
    }
    _.forEach(['Ams', 'Ifn', 'Trp', 'Sbr', 'Oth', 'Gnr5'], (crs) => {
      dto.iniDto[`tktIn${crs}Flg`] = flg[`tktIn${crs}Flg`]
      dto.iniDto[`tktIn${crs}Path`] = tktImport[`tktIn${crs}Path`].text
      dto.iniDto[`tktIn${crs}S3Path`] = tktImport[`tktIn${crs}S3Path`].text
    })

    TicketApi.inputSetting(dto).then((res) => {
      if (res.payload.code === '000') {
        appAction.setMessage({message: res.payload.messageList})
        props.onSuccess()
      } else {
        appAction.setMessage({message: res.payload.messageList})
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  const execDelete = (crs) => {
    console.log(crs)
    console.log(tktImport[`tktIn${crs}Path`])
    if (!tktImport[`tktIn${crs}Path`].text) return console.log('not deleted')

    appAction.showProcessing({isProcessing: true})
    TicketApi.inputFileDelete({
      crs: crs,
    }).then((res) => {
      if (res.payload.messageList) {
        appAction.setMessage({message: res.payload.messageList,})
      } else {
        appAction.setMessage('')
        tktImportAction.changeInputTktImport(convert.getTarget(tktImport[`tktIn${crs}Path`], 'text', ''))
      }
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })
  }

  return (
    <React.Fragment>
      <Container fluid className={props.isModal?'':'main'}>
        <Title title={TITLE.ticketImport.name} icon={TITLE.ticketImport.icon}/>
        <div>
          <ProcessingPopup 
            popup={procPopup}
            setPopup={setProcPopup}
            header={IMPORT_MESSAGE.header}
            processingTitle={IMPORT_MESSAGE.processingTitle}
            processingMessage1={IMPORT_MESSAGE.processingMessage1}
            resultTitle={IMPORT_MESSAGE.resultTitle}
            resultMessage1={IMPORT_MESSAGE.resultMessage1}
          />
        </div>
        <Card
          root={props.isModal?'popup':'max'}
          size='lg'
          hideClose
          header={null}
          subheader={null}
          body={
            <React.Fragment>
              <Card
                root='max-button'
                size='lg'
                hideClose
                header={null}
                subheader={null}
                body={
                  <React.Fragment>
                    {props.isModal &&
                      <div className='mb-3'>
                        <label sm="3" className='label-color m-0 mb-2 width-p-100'>
                          {tktImport.label[0]}
                        </label>
                        <Common.Radio
                          btnSize='sm'
                          attrs={{...tktImport.tktInInputType}}
                          // className='mr-5'
                          group={{
                            className: 'width-30 mr-0',
                          }}
                          radios={IN_INPUT_TYPE.filter(r => {
                            if (r.value==='1' && props.isModal) return false
                            return (r.value==='0' && login.auth032==='1')||(r.value==='1' && login.authFtp==='1')
                          })}
                          onChange={(e) => tktImportAction.changeInputTktImport(e)}
                        />
                      </div>
                    }
                    <Table id='import-table' bordered hover>
                      <thead>
                        <tr>
                          <th onClick={(e) => {}} >
                            <Common.Check
                              attrs={{id: 'allSelected', label: '', text: allSelected}}
                              onChange={(e) => {
                                setAllSelected(e.allSelected.text)
                                let crsList = []
                                crsList.push('Ams')
                                if (login.authIfn==='1') crsList.push('Ifn')
                                if (login.authTrp==='1') crsList.push('Trp')
                                if (login.authSbr==='1') crsList.push('Sbr')
                                crsList.push('Oth')
                                crsList.push('Gnr5')
                                _.forEach(crsList, (crs) => {
                                  tktImportAction.changeInputTktImport(convert.getTarget(tktImport[`tktIn${crs}Flg`], 'text', e.allSelected.text))
                                })
                              }}
                            />
                          </th>
                          <th>GDS区分</th>
                          <th colSpan={2} className='text-left'>読込ファイル
                            <span className={props.isModal?'d-none':'ml-3 font-weight-normal'} style={{'font-size': '0.9em'}} >※File Uploaderからアップロードしたファイルが反映されていない場合は、一度トップメニューに戻ってから再度発券データ入力画面を開いてご確認ください。</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {generateComponent('Ams', 'AMADEUS', setAmsPaths)}
                        {generateComponent('Ifn', 'INFINI', setIfnPaths)}
                        {generateComponent('Trp', 'TRAVELPORT', setTrpPaths)}
                        {generateComponent('Sbr', 'SABRE', setSbrPaths)}
                        {generateComponent('Oth', 'OTHER', setOthPaths)}
                        {generateComponent('Gnr5', 'GNR5', setGnr5Paths)}
                      </tbody>
                    </Table>
                  </React.Fragment>
                }
              />
              <Card 
                size='lg'
                root='button'
                hideClose
                header={null}
                subheader={null}
                body={
                  <div className='card-buttom-button'>
                    {!props.isModal &&
                      <Button
                        {...tktImport.upload}
                        className='button-blue ml-auto width-max-content'
                        disabled={app.isProcessing}
                        onClick={() => execUpload()}
                      >
                        {tktImport.upload.label}
                      </Button>
                    }
                    {props.isModal &&
                      <React.Fragment>
                        <Button
                          {...tktImport.cancel}
                          className='button-gray mr-auto width-max-content'
                          disabled={app.isProcessing}
                          onClick={() => props.onClose()}
                        >
                          {tktImport.cancel.label}
                        </Button>
                        <Button
                          {...tktImport.setting}
                          className='button-blue ml-auto width-max-content'
                          disabled={app.isProcessing}
                          onClick={() => execSetting()}
                        >
                          {tktImport.setting.label}
                        </Button>
                      </React.Fragment>
                    }
                  </div>
                }
              />
              {!props.isModal &&
                <React.Fragment>
                  <Card
                    root='errlog'
                    size='lg'
                    hideClose
                    header={null}
                    subheader={null}
                    body={
                      <React.Fragment>
                        <div className='list-header d-flex align-items-end'>
                          <div className='flex-fill'>
                            <span className='list-count ml-3'>{tktImport.errLogList && tktImport.errLogList.length}</span>
                            <span>件 抽出しました</span>
                          </div>
                          <div className=''>
                            前回実施日時：{format('{date:yyyy/MM/dd HH:mm:ss}', {date: tktImport.iniDto && tktImport.iniDto.tktInPredate?new Date(tktImport.iniDto.tktInPredate):''})}
                          </div>
                        </div>
                        <div className='err-list-body' >
                          <Common.Grid
                            thisGrid={grid}
                            setGrid={setGrid}
                            colDef={LIST_ITEMS.errorLog}
                            // masterType={master.type}
                            dataList={tktImport.errLogList}
                            // setRowHeaderWidth={setRowHeaderWidth}
                            // setColWidth={setColWidth}
                            noSelector={true}
                            grid={{
                              isReadOnly: false,
                              allowAddNew: false,
                              // frozenColumns: 1,
                              imeEnabled: false,
                            }}
                            setInput={tktImportAction.setInputTktImport}
                          />
                        </div>
                      </React.Fragment>
                    }
                  />
                  <Card
                    root='button'
                    size='lg'
                    hideClose
                    header={null}
                    subheader={null}
                    body={
                      <div className='card-buttom-button'>
                        <Button
                          {...tktImport.before}
                          className='button-gray mr-auto width-max-content'
                          disabled={app.isProcessing}
                          onClick={() => {
                            appAction.showProcessing({isProcessing: true})
                            appAction.setMessage('')
                            TicketApi.inputSearch({search:{}})
                            .then(res => {
                              tktImportAction.setInputTktImport({errLogList: res.payload.errLogList})
                            })
                            .catch(() =>{})
                            .finally(() => {
                              appAction.showProcessing({isProcessing: false})
                            })
                          }}
                        >
                          {tktImport.before.label}
                        </Button>
                        <Button
                          {...tktImport.csv}
                          className='button-gray ml-auto width-max-content'
                          disabled={app.isProcessing || !tktImport.errLogList || tktImport.errLogList.length < 1}
                          onClick={() => execCsv()}
                        >
                          {tktImport.csv.label}
                        </Button>
                      </div>
                    }
                  />
                </React.Fragment>
              }
            </React.Fragment>
          }
        />
      </Container>
      <FileUploaderInstallPopup 
            popup={fileUploaderInstall}
            setPopup={setFileUploaderInstall}
            setInit={setFileUploaderInstallInit}
      />
    </React.Fragment>
  )
}