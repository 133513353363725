import { axiosInstance, axiosInstanceNoerr } from 'http/index'

export const TicketApi = {
  findSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findDelete: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/delete', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findSearchPattern: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/searchPattern', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findRegistPattern: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/registPattern', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findDeletePattern: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/deletePattern', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findCheckPattern: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/checkPattern', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findLumpUpdate: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/lumpUpdate', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findSearchItem: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/searchDispItem', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  findRegistItem: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/registDispItem', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  getOutSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/find/getOutSearch', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  detailAuth: () => {
    const instance = axiosInstance;
    return instance.get('/ticket/detail/auth').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  detailSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/detail/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  detailRegister: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/detail/register', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  detailDelete: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/detail/delete', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  detailRegistItem: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/detail/registDispItem', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  calcCommonAmount: (data) => {
    const instance = axiosInstanceNoerr;
    return instance.post('/ticket/detail/calcCommonAmount', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  calcTokui: (data) => {
    const instance = axiosInstanceNoerr;
    return instance.post('/ticket/detail/calcTokui', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  calcFltProductKbAMT: (data) => {
    const instance = axiosInstanceNoerr;
    return instance.post('/ticket/detail/calcFltProductKbAMT', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  calcTotalAmountNFA: (data) => {
    const instance = axiosInstanceNoerr;
    return instance.post('/ticket/detail/calcTotalAmountNFA', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  inputSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/input/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  inputUpload: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/input/upload', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  inputCsv: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/input/download', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  inputSetting: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/input/edit', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  inputFileDelete: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/input/fileDelete', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/output/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputDownload: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/output/download', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputFile: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/output/file', data, {responseType: 'blob'}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  outputSetting: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/output/edit', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  // outputCsv: (data) => {
  //   const instance = axiosInstance;
  //   return instance.post('/ticket/output/csv', data).then(
  //     response => {
  //       const payload = response.data
  //       return {payload}
  //     }
  //   );
  // },
  excelInit: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/excel/init', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  excelSearch: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/excel/search', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  excelDownload: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/excel/download', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  excelEdit: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/excel/edit', data).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  excelFile: (data) => {
    const instance = axiosInstance;
    return instance.post('/ticket/excel/file', data, {responseType: 'blob'}).then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  },
  getSftpSubPathList: () => {
    const instance = axiosInstanceNoerr;
    return instance.get('/ticket/input/getSftpSubPathList').then(
      response => {
        const payload = response.data
        return {payload}
      }
    );
  }
}
