import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as wjInput from '@grapecity/wijmo.react.input';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Card } from 'components';
import { FILE_UPLODER_DOWNLOAD_URL } from 'constants/index';
import { AppAction } from 'ducks/App';
import { bindActionCreators } from 'redux';

export const FileUploaderInstallPopup = React.forwardRef((props, ref) => {

  const app = useSelector(state => state.App);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const initPopup = (form) => {
    props.setPopup(form)
  }
  const [check, setCheck] = React.useState(false)

  React.useEffect(() => {
    props.setInit(true)
  }, [])

  return (
    <wjInput.Popup
      isDraggable={true}
      id={props.id}
      initialized={(form) => initPopup(form)}
      fadeIn={false}
      fadeOut={false}
      hideTrigger='None'
    >
      <Card
        root='popup'
        size='lg'
        hideClose
        header='URI-Pro File Uploaderインストール'
        headerClass='wj-dialog-header'
        body={
          <React.Fragment>
            <p>発券データ入力を実行するには、URI-Pro File Uploaderのインストールが必要です。</p>
            <a
              href={FILE_UPLODER_DOWNLOAD_URL}
              class='ml-5'
              onClick={() => appAction.setInputApp({isUnloadCheck: false})}
            >URI-Pro File Uploaderのインストールはこちら</a>
            <Form.Check
              id='fileUploaderModalFg'
              type='checkbox'
              className='mt-3 ml-4'
              label='以降表示しない'
              checked={!!check}
              onChange={() => setCheck(!check)}
            />
            <Button
              className='button-gray mr-auto ml-auto float-right'
              onClick={() => {
                localStorage.setItem('fileUploaderModalFg', check)
                props.popup.hide()
              }}
              disabled={app.isProcessing}
            >
              <span className='material-icons-outlined md mr-2'>remove_circle_outline</span>
              閉じる
            </Button>
          </React.Fragment>
        }
      />
    </wjInput.Popup>
  )
})