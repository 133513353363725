import React from 'react';
import { useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { 
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  DropdownButton,
  Badge,
} from 'react-bootstrap';
import { Menu } from '@grapecity/wijmo.react.input';
import { AppAction } from 'ducks/App';

import _ from 'lodash';
import { LEFT_MENU, CENTER_MENU, RIGHT_MENU } from 'constants/menu';
import { alert } from "lib";
import { MESSAGE } from 'constants/message';
// import { useSubscription } from 'react-stomp-hooks';
import { WS_URL } from 'constants/index';
import SockJsClient from 'react-stomp';
import { TITLE } from 'constants/title';
import { CLIENT_DOWNLOAD_URL, FILE_UPLODER_DOWNLOAD_URL } from 'constants/index';
import { MntLoginPopup } from './MntLoginPopup'
import { LogoutApi, TicketApi } from 'webapi';
import { persistStore } from 'redux-persist'
import { configureStore, persistor, store } from 'store'
import { LoginAction } from 'ducks/Login';

export const HeaderComponent = (props) => {

  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);

  const [popup, setPopup] = React.useState(null)
  const [showMntLogin, setShowMntLogin] = React.useState(false)
  const [infoCount, setInfoCount] = React.useState('')
  const [alertCount, setAlertCount] = React.useState('')
  const [newTktWindow, setNewTktWindow] = React.useState(false)
  const [mntToolOpen, setMntToolOpen] = React.useState(false)

  // const [lastMessage, setLastMessage] = React.useState("No message received yet");
  // // TODO:login001=>ログインユーザ
  // useSubscription(`/result/login001`, (message) => {
  //   console.log(message)
  //   console.log(JSON.parse(message.body))
  //   setLastMessage(message.body)
  // });

  // React.useEffect(() => {
  //   console.log(location)
  // }, [location])

  React.useEffect(() => {
    // console.log(app.processingList)
    const beforeSeq = sessionStorage.getItem('processedSeq')
    // console.log(beforeSeq?_.toNumber(beforeSeq):-1)
    // 処理済み一覧
    const processedList = _.filter(app.processingList, r => {
      return (r.processKind === 0 && r.cmnMbrcd === login.loginCd && r.processingResult!== null && beforeSeq && r.processingSeq > _.toNumber(beforeSeq))
    })
    // console.log(processedList)
    const list =_.orderBy(processedList, ['processingSeq'], ['desc'])
    if (list.length > 0) {
      // console.log(list.length)
      // console.log(list[0].processingSeq)
      setAlertCount(list.length)
    } else {
      // console.log('no list.')
      if (app.processingList && app.processingList.length > 0) {
        const initList = _.orderBy(app.processingList, ['processingSeq'], ['desc'])
        sessionStorage.setItem('processedSeq', initList[0].processingSeq)
      }
      setAlertCount('')
    }
  }, [app.processingList, login.loginCd, sessionStorage.getItem('processedSeq')])

  const onSelectItem = (e, value) => {
    // console.log(value)
    const key = (e.itemsSource)?e.itemsSource[e.selectedIndex]:value
    // console.log(key)
    if (key.name === 'message') {
      if (app.message.length > 0) {
        const icon = app.messageObject[0].type
        const alertToast = alert({
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          cancelButtonText: '閉じる',
        })
        if (alertToast.isVisible()) {
          alertToast.close()
        } else {
          alertToast.fire({
            icon: icon,
            title: <div>{app.message}</div>,
          })
        }
      }
    } else if (key && key.link) {
      if (key.link === TITLE.ticketEdit.mainLink + '/_new') {
        setNewTktWindow(true)
      } else if (key.link === TITLE.mntLogin.link) {
        setShowMntLogin(true)
      } else if (key.link === TITLE.mntTool.link) {
        setMntToolOpen(true)
      } else if (key.link === CLIENT_DOWNLOAD_URL) {
        // PLUSクライアントDownload
        const linkEl = document.createElement('a');
        linkEl.href = CLIENT_DOWNLOAD_URL
        document.body.appendChild(linkEl);
        props.setIsUnloadCheck(false)
        linkEl.click();
        setTimeout(() => {
          props.setIsUnloadCheck(true)
          linkEl.parentNode.removeChild(linkEl);
        })
      } else if (key.link === FILE_UPLODER_DOWNLOAD_URL) {
        // ファイルアップローダDownload
        const linkEl = document.createElement('a');
        linkEl.href = FILE_UPLODER_DOWNLOAD_URL
        document.body.appendChild(linkEl);
        props.setIsUnloadCheck(false)
        linkEl.click();
        setTimeout(() => {
          props.setIsUnloadCheck(true)
          linkEl.parentNode.removeChild(linkEl);
        })
      } else if (key.link === TITLE.logout.link) {
        alert().fire({
          icon: MESSAGE.confirmLogout().icon,
          title: MESSAGE.confirmLogout().text
        })
        .then((result) => {
          if (result.isConfirmed) {
            LogoutApi.logout()
            .then(res => {
            }).catch(err => {
            }).finally(() => {
              appAction.clearSession()
              persistor.purge()
              loginAction.setInputLogin({
                loginCd: '',
                office: '',
                authCode: '',
                mbrCd: '',
                showConfirm: false,
                forceLogin: false,
                needReLogin: false,
              })
              appAction.setInputApp({
                isHeaderShow: false,
                isFooterShow: false,
              })
              history.push(TITLE.main.link, {init: true})
              window.close()
            })
          }
        })
      } else {
        history.push(key.link, {...key.params, init: true})
      }
    }
  }

  React.useEffect(() => {
    if (mntToolOpen) {
      window.open(app.mntToolUri, 'mnt-tool')
      setMntToolOpen(false)
    }
  }, [mntToolOpen])

  React.useEffect(() => {
    if (newTktWindow) {
      setNewTktWindow(false)
      TicketApi.detailAuth()
      .then(res => {
        if (res.payload === 'auth') {
          const thisTime = _.toString((new Date()).getTime()).substring(4)
          let tktNewList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[{name:'', value:'', mode: ''}]
          tktNewList.push({
            name: '未登録',
            value: '_new' + thisTime,
            mode: '0',
          })
          // console.log(login)
          sessionStorage.setItem('authCode', login.authCode)
          sessionStorage.setItem('loginCd', login.loginCd)
          sessionStorage.setItem('mbrCd', login.mbrCd)
          sessionStorage.setItem('office', login.office)
          sessionStorage.setItem('mntOffice', login.mntOffice)
          localStorage.setItem('tktNewList', JSON.stringify(tktNewList))
          window.open(`${TITLE.ticketEdit.mainLink}/_new${thisTime}`, '_tktEdit_new' + thisTime)
        }
      })
      .catch(() =>{})
      .finally(() => {})
    }
  }, [newTktWindow])

  React.useEffect(() => {
    if (showMntLogin) {
      // console.log(popup)
      popup.show(true, (sender) => {
        // if (document.getElementById(['uploadPath'])) document.getElementById(['uploadPath']).value = '';
        if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
          // console.log('ok')
        } else {
          // console.log('cancel')
        }
        setShowMntLogin(false)
      })
    }
  }, [showMntLogin])

  const generateMenu = (list) => {
    let render = []
    _.forEach(list, (value, key) => {
      const msgClass1 = `${key==='message' && 'mx-auto'}`
      const msgClass2 = `${key==='message' && app.message.length<1 && 'd-none'}`
      const name = (key === 'user')?''+login.loginCd : value.name
      const office = (key === 'user')?(login.mntOffice?login.mntOffice:login.office)+':' : ''
      const count = '1'
      const icon = `<span class='material-icons${value.icon.style}'>${value.icon.name}</span>
                    ${key==='info'?'<span class="badge-color warn badge badge-pill mb-3 ml-minus10">'+infoCount+'</span>':''}
                    ${key==='alert'?'<span class="badge-color warn badge badge-pill mb-3 ml-minus10">'+alertCount+'</span>':''}`
      
      const caret = value.sub?`<span class='material-icons caret'>arrow_drop_down</span>`:''
      const header = `${icon}${caret}<div class='menu-name'>${office}${name}</div>`

      render.push(
        <div
          key={key}
          onClick={(e) => {
            if (login[`${value.auth}`] && login[`${value.auth}`] !== '1') {
              e.preventDefault()
              e.stopPropagation()
            } else {
              onSelectItem(e, value)
            }
          }}
          className={`${value.className} ${msgClass1} ${msgClass2}`}
        >
          <Menu
            id={`menu-item-${key}`}
            header={header}
            displayMemberPath="header"
            subItemsPath="items"
            showDropDownButton={false}
            // openOnHover={!_.isUndefined(value.sub)}//ホバーで開く
            openOnHover={false}
            isAnimated={true}
            itemsSource={value.sub && value.sub.filter(r => {
              if (r.link === TITLE.mntLogin.link || r.link === TITLE.mntTool.link) {
                if (app.isStaff) return r
              } else {
                return r
              }
            })}
            itemClicked={(e) => onSelectItem(e)}
            isDisabled={login[`${value.auth}`] && login[`${value.auth}`] !== '1'}
            command={{
              canExecuteCommand: (param) => {
                return !(login[`${param.auth}`] && login[`${param.auth}`] !== '1')
              }
            }}
          />
        </div>
      )
    })
    return render;
  }
  return (
    <div className={`${app.isHeaderShow?'':'d-none'}`}>
      <MntLoginPopup
        popup={popup}
        setPopup={setPopup}
      />
      <Navbar sticky='top' className='menu-top'>
        {generateMenu(LEFT_MENU)}
        {generateMenu(CENTER_MENU)}
        {generateMenu(RIGHT_MENU)}
      </Navbar>
      {login.office && login.loginCd && 
        <SockJsClient url={WS_URL} topics={[`/result/${login.loginCd}`]}
          onMessage={(msg) => {
            // console.log(msg)
            if (msg.code === '000' || msg.code === '100') {
              appAction.setBatchResult({processingList: msg.resultList})
            } else {
              // appAction.setBatchResult([])
            }
          }}
        />
      }
    </div>
  )
}

HeaderComponent.defaultProps = {};
