import _ from 'lodash';
import { isDate, isValid, format } from 'date-fns';
import { DateTime } from '@grapecity/wijmo';

export const convert = {
  // 更新対象オブジェクト，要素名，値
  getTarget: (object, name, value) => {
    const target = {id: object.id}
    target[object.id] = {
      ...object,
    }
    target[object.id][name] = value
    return target
  },
  toSingleByte: (input) => {
    const isNum = _.isNumber(input)
    input =(!input) ? '': String(input);
    input = _.replace(input, /[！-｝]/g, (s) => {return String.fromCharCode( s.charCodeAt(0) - 0xFEE0 );})
    //// 文字コードシフトで対応できない文字の変換
    input = _.replace(input, /あ/g, 'a')
    input = _.replace(input, /い/g, 'i')
    input = _.replace(input, /う/g, 'u')
    input = _.replace(input, /え/g, 'e')
    input = _.replace(input, /お/g, 'o')
    input = _.replace(input, /”/g, '"')
    input = _.replace(input, /’/g, '\'')
    input = _.replace(input, /‘/g, '`')
    input = _.replace(input, /「/g, '[')
    input = _.replace(input, /」/g, ']')
    input = _.replace(input, /、/g, ',')
    input = _.replace(input, /。/g, '.')
    input = _.replace(input, /￥/g, '\\')
    input = _.replace(input, /　/g, ' ')
    input = _.replace(input, /ー/g, '-')
    input = _.replace(input, /〜/g, '~')
    input = _.replace(input, /ー/g, '-')
    input = _.replace(input, /・/g, '/')
    
    return isNum?_.toNumber(input):input;
  },
  toDateString: (dt, fmt) => {
    const fm = fmt?fmt:"yyyy-MM-dd'T00:00:00.000'"
    const date = dt?new Date(dt):dt;
    return !_.isUndefined(dt)  && !_.isNull(dt)&&(dt.length!==0)&&isDate(date)&&isValid(date)?format(date,fm):date;
  },
  toDateTimeString: (dt, fmt) => {
    const fm = fmt?fmt:"yyyy-MM-dd'T'HH:mm:ss.SSS"
    const date = dt?new Date(dt):dt;
    return !_.isUndefined(dt) && !_.isNull(dt)&&(dt.length!==0)&&isDate(date)&&isValid(date)?format(date,fm):date;
  },
  time2string: (dt, fmt) => {
    const fm = fmt?fmt:"HH:mm"
    const date = dt?new Date(dt):dt;
    return !_.isUndefined(dt) && !_.isNull(dt)&&(dt.length!==0)&&isDate(date)&&isValid(date)?format(date,fm):date;
  },
  hhmm2string: (hhmm, fmt) => {
    const hh = (_.isNull(hhmm) || hhmm.length!==4)?'00':hhmm.substring(0,2)
    const mm = (_.isNull(hhmm) || hhmm.length!==4)?'00':hhmm.substring(2,4)
    const fm = fmt?fmt:"HH:mm"
    const date = new Date();
    date.setHours(hh)
    date.setMinutes(mm)
    return isDate(date)&&isValid(date)?format(date,fm):hhmm;
  },
  hhmm2date: (hhmm) => {
    if (_.isNull(hhmm) || hhmm.length!==4) return hhmm
    const hh = (_.isNull(hhmm) || hhmm.length!==4)?'00':hhmm.substring(0,2)
    const mm = (_.isNull(hhmm) || hhmm.length!==4)?'00':hhmm.substring(2,4)
    const date = new Date();
    date.setHours(hh)
    date.setMinutes(mm)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return isDate(date)&&isValid(date)?date:hhmm;
  },
  datehhmm2string: (datehhmm, fmt) => {
    if (!datehhmm || datehhmm.length!==12) return datehhmm
    const yy = datehhmm.substring(0,4)
    const mi = datehhmm.substring(4,6) - 1
    const dd = datehhmm.substring(6,8)
    const hh = datehhmm.substring(8,10)
    const mm = datehhmm.substring(10,12)
    const fm = fmt?fmt:"yyyy/MM/dd HH:mm"
    const date = new Date(yy, mi, dd, hh, mm);
    return isDate(date)&&isValid(date)?format(date,fm):datehhmm;
  },
  yyyymmdd2string: (yyyymmdd, fmt) => {
    if (!yyyymmdd || yyyymmdd.length!==8) return yyyymmdd
    const yy = yyyymmdd.substring(0,4)
    const mi = yyyymmdd.substring(4,6) - 1
    const dd = yyyymmdd.substring(6,8)
    const fm = fmt?fmt:"yyyy/MM/dd"
    const date = new Date(yy, mi, dd);
    return isDate(date)&&isValid(date)?format(date,fm):yyyymmdd;
  },
  mmyy2dateString: (mmyy, dd, fmt) => {
    if (!mmyy || mmyy.length!==5) return mmyy
    const mm = mmyy.substring(0,2) - 1
    const yy = mmyy.substring(3,5)
    const tempDate = new Date('20' + yy, mm, '01');
    const fm = fmt?fmt:"yyyy/MM/dd"
    let dt = DateTime
    const date = dd==='99'?dt.monthLast(tempDate):new Date('20' + yy, mm, dd);
    return isDate(date)&&isValid(date)?format(date,fm):mmyy;
  },
  mmdd2dateString: (mmdd, fmt) => {
    if (!mmdd || (mmdd.length!==4 && mmdd.length!==5)) return mmdd
    const mm = mmdd.substring(0,2) - 1
    const dd = mmdd.length===4?mmdd.substring(2,4):mmdd.substring(3,5)
    const yyyy = new Date().getFullYear()
    const tempDate = new Date(null, mm, dd);
    const fm = fmt?fmt:"yyyy/MM/dd"
    let dt = DateTime
    const date = new Date(yyyy, mm, dd);
    return isDate(date)&&isValid(date)?format(date,fm):mmdd;
  },
  toDate: (text) => {
    if (!text) {
      return null
    } else if (text.length===8) {
      const yy = text.substring(0,4)
      const mm = text.substring(4,6) - 1
      const dd = text.substring(6,8)
      return new Date(yy, mm, dd, '00', '00', '00');
    } else if (text.length===10){
      const yy = text.substring(0,4)
      const mm = text.substring(5,7) - 1
      const dd = text.substring(8,10)
      return new Date(yy, mm, dd, '00', '00', '00');
    } else if (text.length===12){
      const yy = text.substring(0,4)
      const mm = text.substring(4,6) - 1
      const dd = text.substring(6,8)
      const hh = text.substring(8,10)
      const mi = text.substring(10,12)
      return new Date(yy, mm, dd, hh, mi, '00');
    } else if (text.length===17){
      const yy = text.substring(0,4)
      const mm = text.substring(4,6) - 1
      const dd = text.substring(6,8)
      const hh = text.substring(8,10)
      const mi = text.substring(10,12)
      const ss = text.substring(12,14)
      const sss = text.substring(14,17)
      return new Date(yy, mm, dd, hh, mi, ss, sss);
    } else {
      return null
    }
  },
  timeString2HHmmDate: (text) => {
    if (!text) {
      return null
    } else if (text.length===4) {
      // HHmm
      const hh = text.substring(0,2)
      const mm = text.substring(2,4)
      return new Date('2000', '00', '01', hh, mm, '00');
    } else if (text.length===5){
      // HH:mm
      const hh = text.substring(0,2)
      const mm = text.substring(3,5)
      return new Date('2000', '00', '01', hh, mm, '00');
    } else if (text.length===6){
      // HHmmss
      const hh = text.substring(0,2)
      const mm = text.substring(2,4)
      const ss = text.substring(4,6)
      return new Date('2000', '00', '01', hh, mm, ss);
    } else if (text.length===8){
      // HH:mm:ss
      const hh = text.substring(0,2)
      const mm = text.substring(3,5)
      const ss = text.substring(6,8)
      return new Date('2000', '00', '01', hh, mm, ss);
    } else {
      return null
    }
  },
  toHalfWidth: (value) => {
    if (!value) return value

    // 半角に変換
    value = value.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, function(s){
      return String.fromCharCode(s.charCodeAt(0)-0xFEE0);
    });
    
    return value
  },
  cardNoPadding: (value) => {
    let cardNoPadding = '0'.repeat(12)
    if (value === 'DC') cardNoPadding = '0'.repeat(10)
    else if (['AX', 'TP'].includes(value)) cardNoPadding = '0'.repeat(11)
    return cardNoPadding
  }
}
