import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import * as AppRoute from 'routes/AppRoute';
import { Header, Footer, Processing, ForceLoginPopup, ErrorBoundary, ReLoginPopup, NeedReLoginPopup} from 'components';
import { MigrationPopup } from 'components/migration/MigrationPopup';
import { AppAction } from 'ducks/App';
import { LoginAction } from 'ducks/Login';
import { Container } from 'react-bootstrap';
import { alert, getInitData, convert } from "lib";
import _ from 'lodash';
import { TktEditAction } from 'ducks/TktEdit';
import { LoginApi, MainApi, ProcessApi, LogoutApi } from 'webapi';
import { AUTH_URL } from 'constants/index';
import { ErrorComponent } from 'components/ErrorComponent';
import { TITLE } from 'constants/title';
import { persistStore } from 'redux-persist'
import { configureStore } from 'store'
import Iframe from 'react-iframe'
import frameImage from 'images/1A_URI-Pro_full_1000x192.png';
// import { StompSessionProvider } from 'react-stomp-hooks';

export const AppComponent = () => {
  const app = useSelector(state => state.App);
  const login = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const appAction = bindActionCreators(AppAction, dispatch);
  const loginAction = bindActionCreators(LoginAction, dispatch);
  const tktEditAction = bindActionCreators(TktEditAction, dispatch);
  const history = useHistory();
  const location = useLocation();

  const [loginCd, setLoginCd] = React.useState('')
  const [office, setOffice] = React.useState('')
  const [authCd, setAuthCd] = React.useState('')
  const [isForceLogin, setIsForceLogin] = React.useState(false)
  const [popup, setPopup] = React.useState(null)
  const [popupRe, setPopupRe] = React.useState(null)
  const [popupNeedRe, setPopupNeedRe] = React.useState(null)
  const [mpopup, setMpopup] = React.useState(null)
  const [tktWin, setTktWin] = React.useState(true)
  const [isUnloadCheck, setIsUnloadCheck] = React.useState(true)

  React.useEffect(() => {
    if (app.message.length > 0) {
      const icon = app.messageObject[0].type
      alert({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        cancelButtonText: '閉じる',
        timer: app.messageObject[0].type==='error'?0:5000,
        timerProgressBar: true,
      }).fire({
        icon: icon,
        title: <div>{app.message}</div>,
      })
    } else {
      alert().close()
    }
  }, [app.message])

  React.useEffect(() => {
    // console.log(login.loginCd)
    // appAction.setMasterData({isInitData: false})
    if (location.pathname !=='/error') {
      if (!appAction.isInitData) {
        let timer = setInterval(() => {
          if (login.loginCd && login.mbrCd) {
            // console.log(login.loginCd)
            // console.log(login.mbrCd)
            getInitData(appAction, false, loginAction, login, app, location)
            clearInterval(timer)
          }
        }, 100)
      }
    } else {
      // console.log('error')
      appAction.setMasterData({isInitData: true})
    }
  }, [location.pathname, location.key, login.loginCd, login.mbrCd])

  window.onbeforeunload = (event) => {
    if (isUnloadCheck && app.isUnloadCheck) {
      event.preventDefault();
      event.returnValue = 'confirm';
      }
  };
  
  React.useEffect(() => {
    // console.log(login.loginCd)
    let tktList = []
    let _authCode = login.authCode
    let _loginCd = ''
    let _office = ''
    let _mbrCd = ''
    // 復元
    if (window.name.startsWith('_tktEdit')) {
      setTktWin(false)
      // console.log(localStorage.getItem('tktList'))
      if (window.name === '_tktEditList') {
        tktList = localStorage.getItem('tktList')?JSON.parse(localStorage.getItem('tktList')):[]
      } else {
        tktList = localStorage.getItem('tktNewList')?JSON.parse(localStorage.getItem('tktNewList')):[]
      }
      _authCode = sessionStorage.getItem('authCode')
      _loginCd = sessionStorage.getItem('loginCd')
      _office = sessionStorage.getItem('office')
      _mbrCd = sessionStorage.getItem('mbrCd')
      // console.log(tktList)
      if (tktList.length < 1) window.close()
      appAction.setInputApp({
        tktList: tktList,
        isHeaderShow: false,
        isFooterShow: true,
      })
      loginAction.setInputLogin({
        authCode: _authCode,
        loginCd: _loginCd,
        office: _office,
        mbrCd: _mbrCd,
      })
      setTimeout(() => {
        setTktWin(true)
      })
    }
    // 開発環境用
    // if (process.env.NODE_ENV === `development`) {
    //   _authCode = '000'
    //   appAction.setInputApp({
    //     isHeaderShow: true,
    //     isFooterShow: true,
    //   })
    //   loginAction.setInputLogin({
    //     loginCd: 'NSW123456TES001',
    //     mbrCd: 'ADMIN',
    //     office: 'uripro001',
    //     // loginCd: 'TYOJN2501TES16',
    //     // mbrCd: 'NSW002',
    //     // office: 'TYOJN2501',
    //   })
    // }
    // console.log(login.loginCd)
    // console.log(_authCode)
    if (login.loginCd) return
    if (!_authCode || _authCode!=='000') {
      // console.log(login.loginCd)
      loginAction.setInputLogin({loginCd: _loginCd})
      setLoginCd('')
      setOffice('')
      let timer = setInterval(() => {
        // console.log('----- Login Interval -----')
        if (document.getElementById('auth-frame')) {
          const ifdoc = document.getElementById('auth-frame').contentDocument
          const authCodeEl = ifdoc && ifdoc.getElementById('authCode')
          if (authCodeEl) {
            clearInterval(timer)
            _authCode = authCodeEl.value
            // console.log(authCodeEl.value)
            if (_authCode === '000') {
              const authLoginEl = ifdoc && ifdoc.getElementById('authLogin')
              const authOfficeEl = ifdoc && ifdoc.getElementById('authOffice')
              // console.log(authLoginEl.value)
              // console.log(authOfficeEl.value)
              _loginCd = authLoginEl.value
              _office = authOfficeEl.value
            } else {
              let authMessageEl = document.getElementById('auth-frame').contentDocument.getElementById('authMessage')
              // console.log(_authCode)
              // console.log(authMessageEl.value)
              history.push(TITLE.error.link, {message: [{id:_authCode, message:authMessageEl.value, type:'error'}]})
            }
            setLoginCd(_loginCd)
            setOffice(_office)
            setAuthCd(_authCode)
            // loginAction.setInputLogin({
            //   authCode: _authCode,
            // })
          }
        } else {
          // console.log(document.getElementById('auth-frame'))
          clearInterval(timer)
        }
      }, 300)
    
    } else {
      loginAction.setInputLogin({
        authCode: _authCode,
      })
    }
  }, [login.loginCd])

  React.useEffect(() => {
    if (loginCd && office) {
      execLogin('0')
    }
  }, [loginCd, office])

  const execLogin = (isForce) => {
    appAction.showProcessing({isProcessing: true})
    
    let login = ''
    let mbr = ''
    let authCode = ''
    LoginApi.login(({
      loginCd: loginCd,
      office: office,
      isForceLogin: isForce,
    }))
    .then(res => {
      // console.log(res.payload)
      sessionStorage.removeItem('processedSeq')
      if (res.payload.code === '000') {
        login = res.payload.mbrLoginCd
        mbr = res.payload.mbrCd
        authCode = '000'
        // if (location.pathname===TITLE.main.link) 
        history.replace(TITLE.main.link)
        // if (location.pathname!==TITLE.error.link) {
        //   appAction.setInputApp({
        //     isHeaderShow: true,
        //     isFooterShow: true,
        //   })
        // }

        LoginApi.getKey(() => {})
        .then(res => {//console.log(res)
          // PLUSクライアント起動
          const linkEl = document.createElement('a');
          linkEl.href = 'plusclient:'+res.payload.key;
          document.body.appendChild(linkEl);
          setIsUnloadCheck(false)
          linkEl.click();
          setTimeout(() => {
            setIsUnloadCheck(true)
            linkEl.parentNode.removeChild(linkEl);
          })
          appAction.setInputApp({
            gatewayBaseUri: res.payload.gatewayBaseUri,
            ticketLimit: res.payload.ticketLimit?res.payload.ticketLimit:1,
            mntToolUri: res.payload.mntToolUri,
            clientKey: res.payload.key,
            reloadTime: res.payload.reloadTime?res.payload.reloadTime:10000,
            limitMonth: res.payload.limitMonth?res.payload.limitMonth:0,
          })
        })
        .catch(() =>{})
        .finally(() => {})

      } else if (res.payload.code === '320') {
        // 多重ログインを検知
        // console.log('多重ログイン')
        popup.show(true, (sender) => {
          // if (document.getElementById(['uploadPath'])) document.getElementById(['uploadPath']).value = '';
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
            execLogin('1')
          } else {
            // console.log('cancel')
            history.push(TITLE.error.link, {message: res.payload.messageList})
          }
        })
        // execLogin('1')
      } else {
        history.push(TITLE.error.link, {message: res.payload.messageList})
      }
      loginAction.setInputLogin({
        loginCd: login,
        mbrCd: mbr,
        office: office,
        authCode: authCode,
      })
    })
    .catch(() =>{})
    .finally(() => {
      appAction.showProcessing({isProcessing: false})
    })

  }

  // 権限
  React.useEffect(() => {
  }, [login.loginCd, app.tktCountLimit, app.tktCount, app.termProList])

  React.useEffect(() => {
    if (login.migration > '0') {
      appAction.setInputApp({
        isHeaderShow: false,
        isFooterShow: false,
      })
      // popup
      if (location.pathname === TITLE.error.link) {
      } else if (location.pathname !== TITLE.main.link) {
        history.push(TITLE.main.link)
      } else {
        mpopup.show(true, (sender) => {
          if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
            // console.log('ok')
          } else {
            // console.log('cancel')
          }
        })
      }

    }
  }, [login.migration])

  React.useEffect(() => {
    if (login.needReLogin) {
      popupNeedRe.show(true, (sender) => {
        appAction.setMessage('')
        if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
          loginAction.setInputLogin({reLoginConfirm: true})
          popupRe.show(true, (sender) => {
            if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
              // console.log('ok')
            } else {
              appAction.setInputApp({
                isHeaderShow: false,
                isFooterShow: false,
              })
              loginAction.setInputLogin({
                loginCd: '',
                mbrCd: '',
                office: '',
                authCode: '300',
                needReLogin: false,
              })
            }
            loginAction.setInputLogin({reLoginConfirm: false})
          })
        } else {
          appAction.setInputApp({
            isHeaderShow: false,
            isFooterShow: false,
          })
          loginAction.setInputLogin({
            loginCd: '',
            mbrCd: '',
            office: '',
            authCode: '300',
            needReLogin: false,
          })
        }
      })
    }
    // if (login.migration > '0') {
    //   appAction.setInputApp({
    //     isHeaderShow: false,
    //     isFooterShow: false,
    //   })
    //   // popup
    //   if (location.pathname === TITLE.error.link) {
    //   } else if (location.pathname !== TITLE.main.link) {
    //     history.push(TITLE.main.link)
    //   } else {
    //     mpopup.show(true, (sender) => {
    //       if (sender.dialogResult && sender.dialogResult.indexOf('ok') > -1) {
    //         // console.log('ok')
    //       } else {
    //         // console.log('cancel')
    //       }
    //     })
    //   }
    // }
  }, [login.needReLogin])

  return (
    <React.Fragment>
      <ErrorBoundary>
        {/* <div className={classes.appRoot}> */}
          {location.pathname !== TITLE.error.link &&
            <Processing/>
          }
          {login.authCode !== '000' && location.pathname !== TITLE.error.link &&
            <div>
              <nav className="site-header sticky-top py-1">
                <div className="container d-flex flex-column flex-md-row justify-content-between">
                  <img src={frameImage} alt="" width="256"/>
                </div>
            </nav>
              <Iframe
                src={AUTH_URL}
                width='100%'
                height='100%'
                id='auth-frame'
                display="initial"
                position="absolute"
                sandbox=''
              />
              <footer className="fixed-bottom text-center" style={{backgroundColor: 'rgba(238, 238, 238, 0.85)'}}>
                <div className="row">
                  <div className="col">
                    <small className="d-block text-muted" style={{margin: '20px auto 20px auto'}}>&copy; Amadeus 2017-2020</small>
                  </div>
                </div>
              </footer>
            </div>
          }
          {/* <Header isLoggedIn={account.isLoggedIn && account.isAuthorized}/> */}
          <Container fluid className='appInner'>
            <ForceLoginPopup
              popup={popup}
              setPopup={setPopup}
            />
            <NeedReLoginPopup
              popup={popupNeedRe}
              setPopup={setPopupNeedRe}
            />
            <ReLoginPopup
              popup={popupRe}
              setPopup={setPopupRe}
            />
            <MigrationPopup
              popup={mpopup}
              setPopup={setMpopup}
              setIsUnloadCheck={setIsUnloadCheck}
            />
            <Header isHeaderShow={app.isHeaderShow} setIsUnloadCheck={setIsUnloadCheck} />
            {/* <Common> */}
              <Switch>
                {/* <Route exact path={TITLE.login.link} component={Login}/>
                <Route exact path={TITLE.passwordChange.link} component={PasswordChange}/> */}
                <Route exact path={TITLE.error.link} component={ErrorComponent}/>
                {/* <Auth isLoggedIn={account.isLoggedIn}> */}
                {login.loginCd && login.member && app.isInitData && 
                  <Switch>
                    {renderRoutes(AppRoute.routes)}
                    {/* {renderRoutes(AppRoute.routes)} */}
                  </Switch>
                }
                {/* </Auth> */}
              </Switch>
            {/* </Common> */}
            <Footer isFooterShow={app.isFooterShow} />
          </Container>
        {/* </div> */}
      </ErrorBoundary>
    </React.Fragment>
  )
}
